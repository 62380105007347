import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Material UI
import Button from "@material-ui/core/Button";

// Actions
import { endDraft } from "../../store/actions/draftActions";
import { undoDraftPick } from "../../store/actions/draftActions";

class DraftEnd extends Component {
  handleEndDraft = e => {
    e.preventDefault();
    this.props.endDraft({
      draftId: this.props.id
    });
  };

  handleUndo = () => {
    let orderedPicks = this.props.draftPicks.sort((a, b) => {
      return a.roundNumber - b.roundNumber || a.pickNumber - b.pickNumber;
    });
    let lastPick = orderedPicks[orderedPicks.length - 1];
    lastPick["draftId"] = this.props.id;
    lastPick["status"] = this.props.draft.status;
    this.props.undoDraftPick(lastPick);
  };

  render() {
    const { draft, draftPicks, commish, id } = this.props;
    return (
      <div className="fd-draftroom--board--end">
        <div>
          {commish ? (
            <>
              <h2>The Picks Are In!</h2>
              <p>
                Start voting to complete the draft or undo the last pick to
                re-activate. Previous picks can be found in the chat.
              </p>
            </>
          ) : (
            <>
              <h2>It's a Wrap!</h2>
              <p>
                Waiting for The Commish to finalize the draft and start voting.
              </p>
            </>
          )}
          {commish && (
            <div className="fd-draftroom--board--end--buttons">
              <Button
                type="button"
                variant="contained"
                className="muted"
                onClick={this.handleUndo}
              >
                Undo Last Pick
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={this.handleEndDraft}
              >
                Start Voting
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const draftPicks = state.firestore.ordered.draftPicks;
  return {
    draftPicks: draftPicks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    endDraft: data => dispatch(endDraft(data)),
    undoDraftPick: data => dispatch(undoDraftPick(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    {
      collection: "drafts",
      doc: props.id,
      subcollections: [{ collection: "picks" }],
      orderBy: ["date", "asc"],
      storeAs: "draftPicks"
    }
  ])
)(DraftEnd);
