import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { resendVerificationEmail } from "../store/actions/authActions";

// Material UI
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

// Components
import Navbar from "../components/Navbar";

class Verify extends Component {
  state = {
    alert: "",
    alertOpen: false,
    alertClass: "success"
  };

  // Handle auth errors on prop change
  componentDidUpdate(prevProps) {
    if (this.props.authVerificationSent !== prevProps.authVerificationSent) {
      // The email was sent
      if (this.props.authVerificationSent) {
        this.setState({
          alert: "Email Sent! Please check your inbox.",
          alertOpen: true,
          alertClass: "success"
        });
      } else {
        this.setState({
          alert:
            "There was an error sending the email. If this issue continues, please contact us.",
          alertOpen: true,
          alertClass: "error"
        });
      }
    }
  }

  componentDidMount() {
    // Set page title
    document.title = "Verify Email Address | FunDraft";
  }

  handleEmailSend = () => {
    const data = {
      userId: this.props.auth.uid,
      email: this.props.profile.email
    };
    this.props.resendVerificationEmail(data);
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertOpen: false,
      alert: ""
    });
  };

  render() {
    const { auth, profile } = this.props;

    return (
      <div>
        <Navbar />
        <div className="login">
          <div className="container">
            <div className="column-content">
              {!auth.uid && (
                <>
                  <h1>Verify Your Email</h1>
                  <p>You must be logged in to verify your email address.</p>
                  <Button
                    style={{
                      maxWidth: 200,
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                      marginTop: "20px"
                    }}
                    type="button"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/login"
                  >
                    Login Now
                  </Button>
                </>
              )}

              {auth.uid && profile.status !== "active" && (
                <>
                  <h1>Verify Your Email</h1>
                  <h2>You're almost there!</h2>
                  <p>
                    Your email address must be verified before using FunDraft. A
                    verification email has been sent to{" "}
                    <strong>{auth.email}</strong>, please click the link in that
                    email to verify your address.
                  </p>
                  <h3 style={{ marginTop: "30px" }}>
                    Didn't receive a verification email? Resend it here:
                  </h3>
                  <Button
                    style={{
                      maxWidth: 200,
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                      marginTop: "20px"
                    }}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={this.handleEmailSend}
                  >
                    Re-Send Email
                  </Button>
                </>
              )}

              {auth.uid && profile.status === "active" && (
                <>
                  <h1>Email Verified</h1>
                  <p>Success! You're good to go, let's FunDraft.</p>
                  <Button
                    style={{
                      maxWidth: 200,
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                      marginTop: "20px"
                    }}
                    type="button"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/"
                  >
                    Let's Get Started
                  </Button>
                </>
              )}
            </div>
            {this.state.alert !== "" && (
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={this.state.alertOpen}
                autoHideDuration={4000}
                onClose={this.handleSnackbarClose}
                message={this.state.alert}
                className={this.state.alertClass}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    authVerificationSent: state.auth.authVerificationSent,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resendVerificationEmail: data => dispatch(resendVerificationEmail(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
