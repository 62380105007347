import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../store/actions/authActions";

// Actions
import { updateMuteSetting } from "../store/actions/accountActions";

// MUI Stuff
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// Images
import AppLogo from "../img/fundraft-logo.svg";
import Avatars from "./Avatars";

class Navbar extends Component {
  state = {
    leftNav: false,
    rightNav: false,
    muted: this.props.profile.muted
  };

  handleLeftToggle = () => this.setState({ leftNav: !this.state.leftNav });

  handleRightToggle = () => this.setState({ rightNav: !this.state.rightNav });

  closeToggles = () => this.setState({ leftNav: false, rightNav: false });

  handleMuteChange = e => {
    const userID = this.props.auth.uid;
    this.setState({ rightNav: true });
    let muteSettings = { userID: userID, muted: e.target.checked };
    this.props.updateMuteSetting(muteSettings);
  };

  componentDidUpdate(prevProps) {
    if (this.props.profile.muted !== prevProps.profile.muted) {
      this.setState({ muted: this.props.profile.muted });
    }
  }

  render() {
    const { auth, profile } = this.props;
    return (
      <div className="fd-nav">
        <AppBar position="static">
          <Toolbar
            className={auth.uid ? "fd-nav--bar" : "fd-nav--bar logged-out"}
          >
            <IconButton
              className="hamburger"
              edge="start"
              color="inherit"
              aria-label="Menu"
              onClick={this.handleLeftToggle}
            >
              <MenuIcon />
            </IconButton>
            <div className="fd-nav--logo">
              <NavLink to="/">
                <img src={AppLogo} alt="FunDraft Home" />
              </NavLink>
            </div>
            {auth.uid && (
              <button
                className="fd-nav--avatar"
                onClick={this.handleRightToggle}
              >
                {profile.customAvatar ? (
                  <img
                    src={profile.customAvatar}
                    alt={profile.name + " avatar"}
                  />
                ) : (
                  <img
                    src={Avatars[profile.avatar]}
                    alt={profile.name + " avatar"}
                  />
                )}
              </button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={this.state.leftNav}
          onClose={this.closeToggles}
        >
          <div className="fd-nav--menu" onClick={this.closeToggles}>
            {auth.uid && profile.status === "active" && (
              <>
                <h3>My FunDraft</h3>
                <ul>
                  <MenuItem>
                    <NavLink to="/new">Create a New Draft</NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/my-drafts">My Drafts</NavLink>
                  </MenuItem>
                </ul>
                <Divider />
              </>
            )}
            <h3>Resources</h3>
            <ul>
              <MenuItem>
                <NavLink to="/what-is-fundraft">What is FunDraft?</NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/about-us">About Us</NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </MenuItem>
            </ul>
            <Divider />
            <h3 className="fine-print">Fine Print</h3>
            <ul className="fine-print">
              <MenuItem>
                <a
                  href="https://www.termsfeed.com/live/f5203abb-8163-4abc-8c3d-27c44bf246f7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="https://www.termsfeed.com/live/72f3547a-348a-4971-8acd-2972249703b0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="https://www.termsfeed.com/live/659b517b-24f2-4f12-a1a1-a605607fb9ec"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Disclaimer
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="https://www.termsfeed.com/live/6b20de4a-98d2-4deb-bc8d-f208e52ac58c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cookie Policy
                </a>
              </MenuItem>
            </ul>
          </div>
        </Drawer>
        <Drawer
          anchor="right"
          open={this.state.rightNav}
          onClose={this.closeToggles}
        >
          <div className="fd-nav--account" onClick={this.closeToggles}>
            <p>Logged in as</p>
            <h3>{profile.name}</h3>
            <h4>@{profile.handle}</h4>

            <Divider />

            <ul>
              {profile.status === "active" && (
                <MenuItem>
                  <NavLink to="/account">Edit Account</NavLink>
                </MenuItem>
              )}
              <MenuItem>
                <NavLink to="#" onClick={this.props.signOut}>
                  Sign Out
                </NavLink>
              </MenuItem>
            </ul>

            <Divider />

            {profile.status === "active" && (
              <div className="mute-switch">
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.muted}
                      onChange={this.handleMuteChange}
                      name="muted"
                    />
                  }
                  label="Mute All Sounds"
                />
              </div>
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    updateMuteSetting: data => dispatch(updateMuteSetting(data))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
