import * as firebase from "firebase/app";

// Create the draft record in Firestore
export const createDraft = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .add({
        title: data.title,
        category: data.category,
        visibility: data.visibility,
        participants: data.participants,
        rounds: data.rounds,
        rules: data.rules,
        createdBy: data.createdBy,
        createdByName: data.createdByName,
        preDraftNoteAccepted: [],
        status: "Waitroom",
        currentRound: 1,
        currentPick: 1,
        timerSeconds:
          Number(data.roundHours) * 60 * 60 + Number(data.roundMins) * 60,
        date: new Date(),
        usersArray: firebase.firestore.FieldValue.arrayUnion(data.createdBy),
        usersVotedArray: []
      })
      .then(draft => {
        firestore
          .collection("drafts")
          .doc(draft.id)
          .update({
            draftId: draft.id
          });
        return draft;
      })
      .then(draft => {
        firestore
          .collection("drafts")
          .doc(draft.id)
          .collection("users")
          .doc(data.createdBy)
          .set({
            id: data.createdBy,
            name: data.createdByName,
            draft: draft.id
          });
        return draft;
      })
      .then(draft => {
        dispatch({
          type: "CREATE_DRAFT",
          draftID: draft.id
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: "CREATE_DRAFT_ERROR",
          error: err
        });
      });
  };
};

// Add a user to the 'users' subcollection for a draft
export const addDraftUser = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .doc(data.id)
      .collection("users")
      .doc(data.user)
      .set({
        id: data.user,
        name: data.username,
        draft: data.id
      })
      // Add user ID to array for searching purposes
      .then(() => {
        firestore
          .collection("drafts")
          .doc(data.id)
          .update({
            usersArray: firebase.firestore.FieldValue.arrayUnion(data.user)
          });
      })
      .then(() => {
        dispatch({
          type: "ADD_DRAFT_USER"
        });
      })
      .catch(err => {
        dispatch({ type: "ADD_DRAFT_USER_ERROR", err });
      });
  };
};

// Remove a user from the 'users' subcollection
export const removeDraftUser = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .doc(data.id)
      .collection("users")
      .doc(data.user)
      .delete()
      // Add user ID to array for searching purposes
      .then(() => {
        firestore
          .collection("drafts")
          .doc(data.id)
          .update({
            usersArray: firebase.firestore.FieldValue.arrayRemove(data.user)
          });
      })
      .then(() => {
        dispatch({
          type: "REMOVE_DRAFT_USER"
        });
      })
      .catch(err => {
        dispatch({ type: "REMOVE_DRAFT_USER_ERROR", err });
      });
  };
};

// Set draft status to 'Active'
export const startDraft = data => {
  return (dispatch, getState, getFirebase) => {
    // Extract users from object, create array for sorting
    let usersObject = data.users;
    let users = [];
    for (let key in usersObject) {
      if (usersObject.hasOwnProperty(key)) {
        users.push(usersObject[key]);
      }
    }
    // Randomize order of users for draft
    let randomOrderUsers = [];
    while (users.length !== 0) {
      let randomIndex = Math.floor(Math.random() * users.length);
      randomOrderUsers.push(users[randomIndex]);
      users.splice(randomIndex, 1);
    }
    users = randomOrderUsers;
    // Push random order to arrays
    let draftOrderIds = [];
    let votes = {};
    users.forEach(user => {
      draftOrderIds.push(user.id);
      votes[user.id] = 0;
    });

    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .doc(data.id)
      .update({
        status: "Active",
        draftOrder: draftOrderIds,
        votes: votes
      })
      .then(() => {
        return firestore
          .collection("drafts")
          .doc(data.id)
          .collection("chat")
          .doc()
          .set({
            systemHeader: "Welcome",
            user: "system",
            name: "FunDraft",
            date: new Date(),
            message:
              "The Draft has begun! Anyone with the draft link can view the picks and vote for a winner. This chat is viewable to draft participants only.",
            type: "admin"
          });
      })
      .then(() => {
        dispatch({
          type: "START_DRAFT"
        });
      })
      .catch(err => {
        dispatch({ type: "START_DRAFT_ERROR", err });
      });
  };
};

// Add a draft pick
export const addDraftPick = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    // Keep round number, increment pick by 1
    let newRoundNumber = data.roundNumber;
    let newPickNumber = data.pickNumber + 1;
    // If at the end of a round, increment round, set pick to 1
    if (data.pickNumber === data.participants) {
      newRoundNumber = data.roundNumber + 1;
      newPickNumber = 1;
    }

    // Build object with draft info to update
    let draftUpdate = {
      currentRound: newRoundNumber,
      currentPick: newPickNumber
    };

    // Check if draft is completed, push new status to object
    if (
      data.pickNumber === data.participants &&
      data.roundNumber === data.rounds
    ) {
      draftUpdate["status"] = "Post-Draft";
      draftUpdate["completedDate"] = new Date();
    }

    // Push pick to picks collection in draft
    firestore
      .collection("drafts")
      .doc(data.id)
      .collection("picks")
      .doc()
      .set({
        user: data.user,
        name: data.username,
        date: new Date(),
        pick: data.pick,
        roundNumber: data.roundNumber,
        pickNumber: data.pickNumber,
        draft: data.id
      })
      // Set new round and pick numbers for draft
      .then(() => {
        firestore
          .collection("drafts")
          .doc(data.id)
          .update(draftUpdate);
      })
      // Add draft pick to messages
      .then(() => {
        firestore
          .collection("drafts")
          .doc(data.id)
          .collection("chat")
          .doc()
          .set({
            user: data.user,
            name: data.username,
            date: new Date(),
            message: "Selects " + data.pick,
            type: "pick",
            roundNumber: data.roundNumber,
            pickNumber: data.pickNumber,
            pickSkipped: false
          });
      })
      .then(() => {
        dispatch({
          type: "ADD_DRAFT_PICK"
        });
      })
      .catch(err => {
        dispatch({ type: "ADD_DRAFT_PICK_ERROR", err });
      });
  };
};

// Skip a draft pick
export const skipDraftPick = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    // Keep round number, increment pick by 1
    let newRoundNumber = data.currentRound;
    let newPickNumber = data.currentPick + 1;

    // If at the end of a round, increment round, set pick to 1
    if (data.currentPick === data.participants) {
      newRoundNumber = data.currentRound + 1;
      newPickNumber = 1;
    }

    // Build object with draft info to update
    let draftUpdate = {
      currentRound: newRoundNumber,
      currentPick: newPickNumber
    };

    // Check if draft is completed, push new status to object
    if (
      data.currentPick === data.participants &&
      data.currentRound === data.rounds
    ) {
      draftUpdate["status"] = "Post-Draft";
      draftUpdate["completedDate"] = new Date();
    }

    let skippedUser = data.draftOrder[data.currentPick - 1];
    if (data.currentRound % 2 === 0) {
      skippedUser = data.draftOrder[data.participants - data.currentPick];
    }

    // Push pick to picks collection in draft
    firestore
      .collection("drafts")
      .doc(data.draftId)
      .collection("picks")
      .doc()
      .set({
        user: skippedUser,
        name: "Skipped",
        date: new Date(),
        pick: "Skipped",
        roundNumber: data.currentRound,
        pickNumber: data.currentPick,
        pickSkipped: true,
        draft: data.draftId
      })
      // Set new round and pick numbers for draft
      .then(() => {
        return firestore
          .collection("drafts")
          .doc(data.draftId)
          .update(draftUpdate);
      })
      // Add draft pick to messages
      .then(() => {
        return firestore
          .collection("drafts")
          .doc(data.draftId)
          .collection("chat")
          .doc()
          .set({
            user: "system",
            name: "Commish",
            date: new Date(),
            message: "Skips Pick",
            type: "pick",
            roundNumber: data.currentRound,
            pickNumber: data.currentPick,
            pickSkipped: true
          });
      })
      .then(() => {
        dispatch({
          type: "SKIP_DRAFT_PICK"
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: "SKIP_DRAFT_PICK_ERROR", err });
      });
  };
};

// Undo a draft pick
export const undoDraftPick = data => {
  return (dispatch, getState, getFirebase) => {
    let draftUpdate = {
      currentRound: data.roundNumber,
      currentPick: data.pickNumber
    };
    if (data.status === "Post-Draft") {
      draftUpdate["status"] = "Active";
      draftUpdate["completedDate"] = "";
    }
    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .doc(data.draftId)
      .collection("picks")
      .doc(data.id)
      .delete()
      // Add user ID to array for searching purposes
      .then(() => {
        return firestore
          .collection("drafts")
          .doc(data.draftId)
          .update(draftUpdate);
      })
      .then(() => {
        return firestore
          .collection("drafts")
          .doc(data.draftId)
          .collection("chat")
          .doc()
          .set({
            user: "system",
            name: "Commish",
            date: new Date(),
            message: "Removes Pick",
            type: "pick",
            roundNumber: data.roundNumber,
            pickNumber: data.pickNumber,
            pickSkipped: false
          });
      })
      .then(() => {
        dispatch({
          type: "UNDO_DRAFT_PICK"
        });
      })
      .catch(err => {
        dispatch({ type: "UNDO_DRAFT_PICK_ERROR", err });
      });
  };
};

// End a draft
export const endDraft = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .doc(data.draftId)
      .update({
        status: "Complete"
      })
      .then(() => {
        return firestore
          .collection("drafts")
          .doc(data.draftId)
          .collection("chat")
          .doc()
          .set({
            systemHeader: "It's Over",
            user: "system",
            name: "FunDraft",
            date: new Date(),
            message:
              "The draft is complete! Anyone with the draft link can vote for the WINNER! Use #FunDraft on social media.",
            type: "admin"
          });
      })
      .then(() => {
        dispatch({
          type: "END_DRAFT"
        });
      })
      .catch(err => {
        dispatch({ type: "END_DRAFT", err });
      });
  };
};

export const acceptPreDraftNote = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .doc(data.draftId)
      .update({
        preDraftNoteAccepted: firebase.firestore.FieldValue.arrayUnion(
          data.userId
        )
      })
      .then(() => {
        dispatch({
          type: "ACCEPT_PREDRAFT_NOTE"
        });
      })
      .catch(err => {
        dispatch({ type: "ACCEPT_PREDRAFT_NOTE_ERROR", err });
      });
  };
};

// Add a message to the 'chat' subcollection for a draft
export const addDraftMsg = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("drafts")
      .doc(data.id)
      .collection("chat")
      .doc()
      .set({
        user: data.user,
        name: data.username,
        date: new Date(),
        message: data.message,
        type: data.type
      })
      .then(() => {
        dispatch({
          type: "ADD_DRAFT_MSG"
        });
      })
      .catch(err => {
        dispatch({ type: "ADD_DRAFT_MSG_ERROR", err });
      });
  };
};

// Add a vote to a draft
export const addUserVote = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    var votesUpdate = {};
    votesUpdate[`votes.${data.team}`] = firebase.firestore.FieldValue.increment(
      1
    );

    firestore
      .collection("drafts")
      .doc(data.id)
      .update(votesUpdate)
      // Add user ID to array for searching purposes
      .then(() => {
        firestore
          .collection("drafts")
          .doc(data.id)
          .update({
            usersVotedArray: firebase.firestore.FieldValue.arrayUnion(data.user)
          });
      })
      .then(() => {
        dispatch({
          type: "ADD_DRAFT_VOTE"
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: "ADD_DRAFT_VOTE_ERROR", err });
      });
  };
};
