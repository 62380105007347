import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Material UI
import Button from "@material-ui/core/Button";

class DraftForm extends Component {
  state = {
    draftURL: window.location.href
  };

  handleCopyURL = () => {
    navigator.clipboard.writeText(this.state.draftURL);
  };

  render() {
    const { user, onTheClock } = this.props;
    return (
      <div className="fd-draftroom--board--post">
        <div className="fd-draftroom--board--post--share">
          <div>
            <h2>Share Draft:</h2>
          </div>
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={this.handleCopyURL}
            >
              Copy Draft Link
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DraftForm;
