import React, { useEffect } from "react";

// Components
import Navbar from "../components/Navbar";

const Disclosures = () => {
  // Set page title
  useEffect(() => {
    document.title = "Disclosures | FunDraft";
  });

  return (
    <div>
      <Navbar />
      <div className="fd-join">
        <div className="container">
          <div className="column-content">
            <h1>Disclosures</h1>
            <div className="text-content">
              <p>
                The American Red Cross name and logo are used with its
                permission, which in no way constitutes an endorsement, express
                or implied, of any product, service, company, opinion or
                political position. The American Red Cross name and logo are
                registered trademarks owned by the American National Red Cross.
                For more information about the American Red Cross, please visit{" "}
                <a
                  href="https://redcross.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  redcross.org
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclosures;
