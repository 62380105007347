import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";

// Components
import DraftVote from "./DraftVote";
import DraftUser from "./DraftUser";
import DraftPicks from "./DraftPicks";
import LoadingWidget from "../LoadingWidget";

class DraftBoard extends Component {
  render() {
    const { id, user, draft } = this.props;

    // Set column width for draft board
    let draftColumnWidth = "100%";
    if (draft) {
      draftColumnWidth = 100 / Number(draft.participants) + "%";
    }

    // Get on the clock user
    const getOnTheClock = () => {
      if (draft && draft.status === "Active") {
        var totalUsers = draft.participants;
        if (draft.currentRound % 2 === 0) {
          var snakePick = totalUsers - draft.currentPick;
          return draft.draftOrder[snakePick];
        } else {
          return draft.draftOrder[draft.currentPick - 1];
        }
      }
      return null;
    };

    // Determine the voting winner
    let winners = [];
    if (draft && draft.votes) {
      // Determine the highest number of votes
      let mostVotes = 0;
      Object.keys(draft.votes).forEach(function(item) {
        if (draft.votes[item] > mostVotes) {
          mostVotes = draft.votes[item];
        }
      });
      // Push users with most votes to winners array
      Object.keys(draft.votes).forEach(function(item) {
        if (draft.votes[item] >= mostVotes) {
          winners.push(item);
        }
      });
    }

    const getUserVotes = userId => {
      let voteCount = 0;
      if (draft) {
        voteCount = draft.votes[userId];
      }
      return voteCount;
    };

    const userIsWinner = userId => {
      if (winners.includes(userId)) {
        return true;
      }
      return false;
    };

    const userHasVoted = userId => {
      if (draft && draft.usersVotedArray.includes(userId)) {
        return true;
      }
      return false;
    };

    const userIsCommish = userId => {
      if (draft.createdBy === userId) {
        return true;
      }
      return false;
    };

    // Show loading spinner if data is loading
    if (!draft) {
      return <LoadingWidget />;
    }

    return (
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        className={
          draft.status === "Complete" || draft.status === "Archived"
            ? "fd-draftroom--board--picks complete"
            : "fd-draftroom--board--picks"
        }
      >
        <thead>
          {(draft.status === "Complete" || draft.status === "Archived") && (
            <tr>
              {draft.draftOrder.map((userId, i) => {
                return (
                  <th key={i}>
                    <DraftVote
                      id={id}
                      team={userId}
                      user={user}
                      votes={draft.votes[userId]}
                      isWinner={userIsWinner(userId)}
                      hasVoted={userHasVoted(user)}
                      multipleWinners={winners.length > 1 ? true : false}
                    />
                  </th>
                );
              })}
            </tr>
          )}
          <tr>
            {[...Array(Number(draft.participants))].map((num, i) => {
              const userId = draft.draftOrder[i];
              return (
                <th key={i} style={{ width: draftColumnWidth }}>
                  <DraftUser
                    id={userId}
                    isWinner={userIsWinner(userId)}
                    hasVoted={userHasVoted(user)}
                    onTheClock={getOnTheClock()}
                    isCommish={userIsCommish(userId)}
                    position={i + 1}
                  />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <DraftPicks
            id={id}
            user={user}
            draft={draft}
            winners={winners}
            onTheClock={getOnTheClock()}
            hasVoted={userHasVoted(user)}
          />
        </tbody>
      </table>
    );
  }
}

export default DraftBoard;
