import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import { withRouter } from "react-router";
import { isSafari } from "react-device-detect";

// Components
import DraftWaitingRoom from "../components/DraftWaitingRoom";
import DraftActive from "../components/DraftActive";
import DraftComplete from "../components/DraftComplete";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import DraftNotFound from "../components/DraftNotFound";

// Sounds
import DraftStartedSFX from "../sound/draft-start.mp3";
import DraftEndedSFX from "../sound/draft-complete.mp3";

class SingleDraft extends Component {
  componentDidUpdate(prevProps) {
    // Verify props for draft exist
    if (prevProps.draft) {
      if (
        this.props.draft.status !== prevProps.draft.status &&
        !this.props.profile.muted &&
        !isSafari
      ) {
        if (
          this.props.draft.status === "Active" &&
          prevProps.draft.status === "Waitroom"
        ) {
          this.playDraftStarted();
        }
        if (
          this.props.draft.status === "Post-Draft" &&
          prevProps.draft.status === "Active"
        ) {
          this.playDraftEnded();
        }
      }
    }
  }

  playDraftStarted = () => {
    let audio = new Audio(DraftStartedSFX);
    audio.play();
  };

  playDraftEnded = () => {
    let audio = new Audio(DraftEndedSFX);
    audio.play();
  };

  render() {
    const { auth, match, draft, profile } = this.props;

    // Redirect if not logged in
    if (!auth.uid) {
      return <Redirect to="/login" />;
    }

    // Draft data is loading
    if (!isLoaded(draft) || !isLoaded(profile)) {
      return <Loading />;
    }

    if (isLoaded(draft) && !draft) {
      return <Loading />;
    }

    // Redirect if logged in but not verified
    if (auth.uid && profile.status !== "active") {
      return <Redirect to="/verify" />;
    }

    // Draft data isn't found
    if (isLoaded(draft) && isEmpty(draft)) {
      return <DraftNotFound />;
    }

    return (
      <div>
        <Navbar />

        {draft.status === "Waitroom" && (
          <DraftWaitingRoom
            id={match.params.id}
            user={auth.uid}
            draft={draft}
          />
        )}

        {(draft.status === "Active" ||
          draft.status === "Post-Draft" ||
          draft.status === "Complete") && (
          <DraftActive id={match.params.id} user={auth.uid} draft={draft} />
        )}

        {draft.status === "Archived" && (
          <DraftComplete id={match.params.id} user={auth.uid} draft={draft} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const drafts = state.firestore.data.drafts;
  const draft = drafts ? drafts[ownProps.match.params.id] : null;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    draft: draft
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
      {
        collection: "drafts",
        doc: props.match.params.id
      }
    ])
  )(SingleDraft)
);
