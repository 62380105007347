import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Functions
import { utilFormatTimestamp } from "../util/functions.js";

// Material UI
import Button from "@material-ui/core/Button";

const DraftListComplete = ({ completeDrafts, showMore, perPage }) => {
  const handleShowMore = () => {
    showMore(perPage);
  };

  // Draft data is loading
  if (!isLoaded(completeDrafts)) {
    return <></>;
  }

  return (
    <div>
      {completeDrafts && completeDrafts.length > 0 ? (
        <>
          <ul className="draft-list">
            {completeDrafts.map((draft, index) => (
              <li key={index}>
                <NavLink to={"/draft/" + draft.id}>
                  <span className="title">{draft.title}</span>
                  <span className="date">
                    ({utilFormatTimestamp(draft.date.seconds)})
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
          {completeDrafts.length % 25 === 0 && (
            <div className="draft-list--show-more">
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowMore}
              >
                Show More
              </Button>
            </div>
          )}
        </>
      ) : (
        <p>No Completed Drafts</p>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    completeDrafts: state.firestore.ordered.completeDrafts
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: "drafts",
      where: [
        ["usersArray", "array-contains", props.auth.uid],
        ["status", "in", ["Complete", "Archived"]]
      ],
      orderBy: ["date", "desc"],
      storeAs: "completeDrafts",
      limit: props.perPage
    }
  ])
)(DraftListComplete);
