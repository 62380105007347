import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { withRouter } from "react-router";

// Components
import DraftPick from "./DraftPick";

// Sounds
import PickMadeSFX from "../../sound/pick-made.mp3";

class DraftPicks extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.picks !== prevProps.picks) {
      // Verify draft picks exists and there's at least one pick
      if (prevProps.picks && this.props.picks && this.props.picks.length > 0) {
        // Play sounds fo picks that are added
        if (
          this.props.picks.length > prevProps.picks.length &&
          !this.props.profile.muted
        ) {
          let orderedPicks = this.props.picks.sort((a, b) => {
            return a.roundNumber - b.roundNumber || a.pickNumber - b.pickNumber;
          });
          let lastPick = orderedPicks[orderedPicks.length - 1];
          // If user matches last draft pick
          if (lastPick.user === this.props.user) {
            // Play if it wasn't skipped and isn't the very last pick
            if (
              lastPick.pick !== "Skipped" &&
              !this.isLastPick(lastPick.roundNumber, lastPick.pickNumber)
            ) {
              this.playPickMade();
            }
          }
        }
      }
    }
  }

  playPickMade = () => {
    let audio = new Audio(PickMadeSFX);
    audio.play();
  };

  isDraftComplete = status => {
    if (status === "Complete") {
      this.setState({ isDraftComplete: true });
    }
  };

  isLastPick = (round, pick) => {
    let totalRounds = this.props.draft.rounds;
    let totalPicks = this.props.draft.participants;
    if (round === totalRounds && pick === totalPicks) {
      return true;
    }
    return false;
  };

  render() {
    const {
      id,
      draft,
      winners,
      onTheClock,
      hasVoted,
      picks,
      profile
    } = this.props;

    // Helper function to get snake-formatted numbers for even rounds
    const snakePickNumber = (roundNumber, pickNumber) => {
      let thisPick = pickNumber + 1;
      if (roundNumber % 2 === 0) {
        thisPick = Number(draft.participants) - pickNumber;
      }
      return thisPick;
    };

    // Return draft pick if made
    const getDraftPick = (roundNumber, pickNumber) => {
      if (verifiedPicks.length) {
        let thisPick = verifiedPicks.filter(p => {
          return p.roundNumber === roundNumber && p.pickNumber === pickNumber;
        });
        if (thisPick.length) {
          return thisPick[0];
        }
      }
      return null;
    };

    let verifiedPicks = [];
    if (picks && picks.length) {
      picks.forEach(pick => {
        if (pick.draft === id) {
          verifiedPicks.push(pick);
        }
      });
    }

    if (!isLoaded(picks) || !isLoaded(profile)) {
      return <></>;
    }

    return (
      <>
        {[...Array(Number(draft.rounds))].map((element, index) => {
          const roundNumber = index + 1;
          return (
            <tr key={index}>
              {[...Array(Number(draft.participants))].map((e, i) => {
                return (
                  <td key={i}>
                    <DraftPick
                      round={roundNumber}
                      pick={snakePickNumber(roundNumber, i)}
                      draft={draft}
                      draftPick={getDraftPick(
                        roundNumber,
                        snakePickNumber(roundNumber, i)
                      )}
                      onTheClock={onTheClock}
                      winners={winners}
                      hasVoted={hasVoted}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    picks: state.firestore.ordered.picks
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
      {
        collection: "drafts",
        doc: props.id,
        subcollections: [{ collection: "picks" }],
        orderBy: ["date", "asc"],
        storeAs: "picks"
      }
    ])
  )(DraftPicks)
);
