import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Actions
import { addUserVote } from "../../store/actions/draftActions";

// Material UI
import Button from "@material-ui/core/Button";

// Images
import CrownIcon from "../../img/crown-icon-white.svg";

class DraftVote extends Component {
  handleUserVote = team => {
    this.props.addUserVote({
      id: this.props.id,
      team: team,
      user: this.props.user
    });
  };

  render() {
    const {
      id,
      team,
      user,
      votes,
      isWinner,
      hasVoted,
      multipleWinners
    } = this.props;

    return (
      <div
        className={
          isWinner && hasVoted
            ? "vote-wrapper first winner"
            : "vote-wrapper first"
        }
      >
        {isWinner && hasVoted && (
          <>
            <div className="vote-icon">
              <img src={CrownIcon} alt="Champ" />
            </div>
            <div className="vote-text">
              {multipleWinners ? "Co-Champ" : "Champ"}
            </div>
          </>
        )}
        {hasVoted ? (
          <div className={isWinner ? "vote-counter winner" : "vote-counter"}>
            {votes}
          </div>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => this.handleUserVote(team)}
          >
            Vote Winner
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const drafts = state.firestore.data.drafts;
  const draft = drafts ? drafts[ownProps.id] : null;
  return {
    draft: draft,
    draftPicks: state.firestore.ordered.draftPicks,
    draftUsers: state.firestore.ordered.draftUsers,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUserVote: data => dispatch(addUserVote(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    {
      collection: "drafts",
      doc: props.id
    },
    {
      collection: "drafts",
      doc: props.id,
      subcollections: [{ collection: "picks" }],
      orderBy: ["date", "asc"],
      storeAs: "draftPicks"
    },
    {
      collection: "drafts",
      doc: props.id,
      subcollections: [{ collection: "users" }],
      storeAs: "draftUsers"
    }
  ])
)(DraftVote);
