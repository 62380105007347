import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Components
import AddUser from "./waitingroom/AddUser";
import RemoveUser from "./waitingroom/RemoveUser";
import UserList from "./waitingroom/UserList";
import StartDraft from "./waitingroom/StartDraft";
import Loading from "../components/Loading";

// Material UI
import Button from "@material-ui/core/Button";

// Images
import ListIconOrange from "../img/list-icon-orange.svg";
import CloseIconWhite from "../img/close-icon-white.svg";

class DraftWaitingRoom extends Component {
  state = {
    modalOpen: false,
    draftURL: window.location.href
  };

  handleModalToggle = e => {
    e.preventDefault();
    if (this.state.modalOpen === false) {
      this.setState({
        modalOpen: true
      });
    } else {
      this.setState({
        modalOpen: false
      });
    }
  };

  handleCopyURL = () => {
    navigator.clipboard.writeText(this.state.draftURL);
  };

  render() {
    const { id, user, profile, draft, draftUsers } = this.props;

    var confirmedUsers = [];
    if (draftUsers) {
      if (draftUsers.length) {
        draftUsers.forEach(user => {
          if (user.draft === id) {
            confirmedUsers.push(user);
          }
        });
      }
    }

    var userIds = [];
    if (confirmedUsers.length) {
      confirmedUsers.forEach(user => {
        userIds.push(user.id);
      });
    }
    var userClaims = confirmedUsers.length;

    // Draft data is loading
    if (!isLoaded(profile) || !isLoaded(draft) || !isLoaded(draftUsers)) {
      return <Loading />;
    }

    return (
      <div>
        <div className="container">
          <div className="twelve columns">
            <div className="fd-waitroom">
              <h4>Let's Draft</h4>
              <h1>{draft.title}</h1>
              <div className="fd-waitroom--details">
                ({draft.rounds} rounds, {draft.participants} participants)
              </div>

              <div className="fd-waitroom--rules">
                <button onClick={this.handleModalToggle}>
                  <img src={ListIconOrange} alt="Rules for this Draft" />
                  Rules for this Draft
                </button>
              </div>

              {draft.createdBy === user && (
                <div className="fd-waitroom--link">
                  <h2>Invite others to this draft:</h2>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={this.handleCopyURL}
                  >
                    Copy Draft Link
                  </Button>
                </div>
              )}

              <div className="fd-waitroom--list">
                <h2>
                  <span>Who Will Be Drafting?</span>
                </h2>
                <UserList
                  users={confirmedUsers}
                  participants={draft.participants}
                  createdBy={draft.createdBy}
                />
              </div>

              {/* User not signed up and draft is not full */}
              {!userIds.includes(user) && userClaims !== draft.participants && (
                <div className="fd-nav--bottom highlight">
                  <AddUser
                    id={id}
                    title={draft.title}
                    date={draft.date}
                    user={user}
                    name={profile.name}
                    avatar={profile.avatar}
                  />
                </div>
              )}

              {/* User signed up and draft is not full */}
              {userIds.includes(user) && userClaims !== draft.participants && (
                <div className="fd-nav--bottom">
                  <RemoveUser
                    id={id}
                    title={draft.title}
                    date={draft.date}
                    user={user}
                    name={profile.name}
                    avatar={profile.avatar}
                  />
                  <h3>Waiting for all spots to be claimed</h3>
                </div>
              )}

              {/* User signed up and draft is full and user is creator */}
              {userIds.includes(user) &&
                userClaims === draft.participants &&
                user === draft.createdBy && (
                  <div className="fd-nav--bottom highlight">
                    <RemoveUser
                      id={id}
                      title={draft.title}
                      date={draft.date}
                      user={user}
                      name={profile.name}
                      avatar={profile.avatar}
                    />
                    <StartDraft id={id} draftUsers={confirmedUsers} />
                  </div>
                )}

              {/* User is signed up and draft is full and user is not creator */}
              {userIds.includes(user) &&
                userClaims === draft.participants &&
                user !== draft.createdBy && (
                  <div className="fd-nav--bottom">
                    <RemoveUser
                      id={id}
                      title={draft.title}
                      date={draft.date}
                      user={user}
                      name={profile.name}
                      avatar={profile.avatar}
                    />
                    <h3>Waiting for The Commish to start draft</h3>
                  </div>
                )}

              {/* User not signed up and draft is not full */}
              {!userIds.includes(user) && userClaims === draft.participants && (
                <div className="fd-nav--bottom">
                  <h3>
                    Waiting for The Commish to start draft. All spots are
                    claimed.
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.modalOpen === true ? "fd-modal open" : "fd-modal"
          }
        >
          <div
            className="fd-modal--overlay"
            onClick={this.handleModalToggle}
          ></div>
          <div className="fd-modal--content">
            {draft.rules && (
              <div>
                <h3>Rules Specific to this Draft:</h3>
                <p>{draft.rules}</p>
              </div>
            )}
            <h3>Basic FunDraft Rules:</h3>
            <ul>
              <li>Draft Order is determined randomly</li>
              <li>No Duplicate Answers</li>
              <li>The Commish can SKIP and UNDO picks</li>
            </ul>
          </div>
          <div className="fd-modal--close" onClick={this.handleModalToggle}>
            <img src={CloseIconWhite} alt="Close Rules" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const draftUsers = state.firestore.ordered.draftUsers;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    draftUsers: draftUsers
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: "drafts",
      doc: props.id,
      subcollections: [{ collection: "users" }],
      storeAs: "draftUsers"
    }
  ])
)(DraftWaitingRoom);
