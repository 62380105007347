// Import reducers
import authReducer from "./authReducer";
import draftReducer from "./draftReducer";
import accountReducer from "./accountReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  draft: draftReducer,
  account: accountReducer
});

export default rootReducer;
