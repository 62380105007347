import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { addDraftUser } from "../../store/actions/draftActions";

// Material UI
import Button from "@material-ui/core/Button";

class AddUser extends Component {
  state = {
    id: this.props.id,
    title: this.props.title,
    date: this.props.date,
    user: this.props.user,
    username: this.props.name,
    avatar: this.props.avatar
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.addDraftUser(this.state);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="user">
          <Button type="submit" variant="contained" color="primary">
            Claim Your Spot
          </Button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addDraftUser: data => dispatch(addDraftUser(data))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AddUser));
