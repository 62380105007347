import React, { useEffect } from "react";

// Components
import Navbar from "../components/Navbar";

// Images
import AppLogo from "../img/fundraft-guys.jpg";

const About = () => {
  // Set page title
  // Set page title
  useEffect(() => {
    document.title = "What is FunDraft? | FunDraft";
  });

  return (
    <div>
      <Navbar />
      <div className="fd-join">
        <div className="container">
          <div className="column-content">
            <h1>About FunDraft</h1>
            <div className="text-content">
              <div className="image-content">
                <div className="image">
                  <figure>
                    <img
                      src={AppLogo}
                      alt="Andy Schneider & Brooks Carmean"
                      className="inline"
                    />
                    <figcaption>Andy Schneider & Brooks Carmean</figcaption>
                  </figure>
                </div>
                <div className="text">
                  <p>
                    FunDraft Co-founders Brooks Carmean and Andy Schneider
                    joined forces through{" "}
                    <a
                      href="https://bit.ly/FantasyFootballersHomepage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      The Fantasy Footballers
                    </a>{" "}
                    Podcast, where Brooks serves as Full-Time Producer and Andy
                    as Lead Designer/Developer. What originally started as
                    drafts in shared spreadsheets and text messages has become a
                    passion project long-in-the-making. And, finally it has
                    happened!
                  </p>
                </div>
              </div>
              <hr />
              <h2>Spitballers Comedy Podcast</h2>
              <p>
                Check out the{" "}
                <a
                  href="https://bit.ly/SpitballersHomepage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Spitballers Podcast
                </a>
                , where hosts Andy, Jason and Mike discuss ridiculous topics,
                answer life’s most difficult questions, and end each weekly
                episode <strong>with a draft of a random topic!</strong> In a
                back catalog of 100+ hours of episodes & laughs, you’ll also
                find plenty of unique FunDraft topic ideas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
