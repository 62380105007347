import * as firebase from "firebase/app";
import { getFirebase } from "react-redux-firebase";
require("firebase/functions");
const sgMail = require("@sendgrid/mail");

// Setup SendGrid API
const sgAPIKey =
  "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";
sgMail.setApiKey(sgAPIKey);

// Log in
export const signIn = credentials => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

// Log out
export const signOut = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

// Create new user
export const createUser = newUser => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = getFirebase().firestore();

    let userName = newUser.name;
    let userHandle = userName.toLowerCase();

    const nameExists = usernameExists(userHandle);
    const nameReserved = usernameReserved(userHandle);

    let userID = "";

    Promise.all([nameExists, nameReserved])
      .then(res => {
        if (res.includes(true)) {
          dispatch({ type: "USER_ALREADY_EXISTS" });
          throw new Error("User Already Exists");
        }
        return;
      })
      .then(() => {
        return firebase
          .auth()
          .createUserWithEmailAndPassword(newUser.email, newUser.password);
      })
      .then(response => {
        // Add user record to database
        userID = response.user.uid;
        return firestore
          .collection("users")
          .doc(response.user.uid)
          .set({
            email: newUser.email,
            name: newUser.name,
            handle: userHandle,
            active: true,
            avatar: "robot",
            status: "pending",
            created: new Date()
          });
      })
      .then(() => {
        return firestore
          .collection("usernames")
          .doc(userID)
          .set({
            handle: userHandle
          });
      })
      .then(() => {
        dispatch({ type: "CREATE_USER_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "CREATE_USER_ERROR", err });
        console.log(err);
      });
  };
};

// Check for existing usernames
const usernameExists = userHandle => {
  const firebase = getFirebase();
  const firestore = getFirebase().firestore();
  return firestore
    .collection("usernames")
    .where("handle", "==", userHandle)
    .get()
    .then(querySnapshot => {
      if (!querySnapshot.empty) {
        return true;
      }
      return false;
    })
    .catch(err => {
      console.log(err);
    });
};

// Check for reserved usernames
const usernameReserved = userHandle => {
  const firebase = getFirebase();
  const firestore = getFirebase().firestore();
  return firestore
    .collection("reservedusers")
    .where("handle", "==", userHandle)
    .get()
    .then(querySnapshot => {
      if (!querySnapshot.empty) {
        return true;
      }
      return false;
    })
    .catch(err => {
      console.log(err);
    });
};

// Verify user email
export const verifyUser = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("users")
      .doc(data.id)
      .update({
        status: "active"
      })
      .then(draft => {
        dispatch({
          type: "VERIFY_USER",
          draftID: draft.id
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: "VERIFY_USER_ERROR",
          error: err
        });
      });
  };
};

// Re-Send Verification Email
export const resendVerificationEmail = data => {
  return dispatch => {
    const resendEmail = firebase
      .functions()
      .httpsCallable("resendVerificationEmail");

    resendEmail(data)
      .then(result => {
        dispatch({
          type: "VERIFY_EMAIL_RESENT"
        });
      })
      .catch(err => {
        dispatch({ type: "VERIFY_EMAIL_RESENT_ERROR", err });
      });
  };
};

// Password reset
export const resetPassword = email => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: "PASSWORD_RESET_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "PASSWORD_RESET_ERROR", err });
      });
  };
};
