const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: action.err
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authError: null
      };
    case "CREATE_USER_SUCCESS":
      return {
        ...state,
        authError: null
      };
    case "USER_ALREADY_EXISTS":
      return {
        ...state,
        authError: { code: "auth/username-taken" }
      };
    case "CREATE_USER_ERROR":
      return {
        ...state,
        authError: action.err
      };
    case "VERIFY_USER":
      return {
        ...state,
        authError: null
      };
    case "VERIFY_USER_ERROR":
      return {
        ...state,
        authError: action.err
      };
    case "VERIFY_EMAIL_RESENT":
      return {
        ...state,
        authVerificationSent: true
      };
    case "VERIFY_EMAIL_RESENT_ERROR":
      return {
        ...state,
        authError: action.err,
        authVerificationSent: false
      };
    case "PASSWORD_RESET_SUCCESS":
      return {
        ...state,
        resetEmailSent: true
      };
    case "PASSWORD_RESET_ERROR":
      return {
        ...state,
        resetEmailSent: false
      };
    default:
      return state;
  }
};

export default authReducer;
