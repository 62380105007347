import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";

// Components
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";

const Home = ({ auth, profile }) => {
  // Set page title
  useEffect(() => {
    document.title = "FunDraft | Draft Anything!";
  });

  // Auth data is loading
  if (!isLoaded(auth, profile)) {
    return <Loading />;
  }

  // Redirect if not logged in
  if (!auth.uid) {
    return <Redirect to="/login" />;
  }

  // Redirect if logged in but not verified
  if (auth.uid && profile.status !== "active") {
    return <Redirect to="/verify" />;
  }

  return (
    <div>
      <Navbar />
      <div className="home">
        <NavLink to="/new" className="home--block">
          <div>
            <h2>Create</h2>
            <h3>a Draft</h3>
          </div>
        </NavLink>
        <NavLink to="/my-drafts" className="home--block">
          <div>
            <h2>View</h2>
            <h3>My Drafts</h3>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Home);
