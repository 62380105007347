import React, { useEffect } from "react";

// Components
import Navbar from "../components/Navbar";

const Contact = () => {
  // Set page title
  useEffect(() => {
    document.title = "Contact Us | FunDraft";
  });

  return (
    <div>
      <Navbar />
      <div className="fd-join">
        <div className="container">
          <div className="column-content">
            <h1>Contact Us</h1>
            <div className="text-content">
              <h2>Have a suggestion or feedback?</h2>
              <p style={{ textAlign: "center", marginTop: 20 }}>
                <a
                  href="https://forms.gle/7y5ge7jhoJFErTAMA"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button primary"
                >
                  Contact FunDraft
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
