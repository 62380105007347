import React, { Component } from "react";

// Functions
import { utilFormatPickNumber } from "../../util/functions.js";

class DraftPick extends Component {
  scrollToPick = () => {
    this.pickRef.current.scrollIntoView({ behavior: "smooth" });
  };

  isPickActive = (round, pick) => {
    if (
      round === this.props.draft.currentRound &&
      pick === this.props.draft.currentPick
    ) {
      return true;
    }
    return false;
  };

  constructor(props) {
    super(props);
    this.pickRef = React.createRef();
  }

  componentDidMount() {
    if (
      (this.props.draft.status !== "Complete" ||
        this.props.draft.status !== "Archived") &&
      this.isPickActive(this.props.round, this.props.pick)
    ) {
      // this.scrollToPick();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.draft !== prevProps.draft) {
      if (
        !this.props.complete &&
        this.isPickActive(this.props.round, this.props.pick)
      ) {
        // this.scrollToPick();
      }
    }
  }

  render() {
    const { round, pick, draft, draftPick, winners, hasVoted } = this.props;

    let voteClass = "vote-wrapper";
    if (round === draft.rounds) {
      voteClass += " last";
    }
    if (
      (draft.status === "Complete" || draft.status === "Archived") &&
      hasVoted &&
      draftPick
    ) {
      if (winners.includes(draftPick.user)) {
        voteClass += " winner";
      }
    }

    return (
      <div>
        {draftPick && (
          <div className={voteClass}>
            <div
              className={
                draftPick.pickSkipped
                  ? "fd-draftroom--board--pick skipped"
                  : "fd-draftroom--board--pick made"
              }
            >
              <span className="number">
                {utilFormatPickNumber(round, pick)}
              </span>
              {draftPick.pickSkipped ? (
                <span className="pick">No Pick</span>
              ) : (
                <span className="pick">{draftPick.pick}</span>
              )}
            </div>
          </div>
        )}
        {!draftPick && (
          <div className={voteClass} ref={this.pickRef}>
            <div
              className={
                this.isPickActive(round, pick)
                  ? "fd-draftroom--board--pick active"
                  : "fd-draftroom--board--pick empty"
              }
            >
              <span className="number">
                {utilFormatPickNumber(round, pick)}
              </span>
              <span className="pick">&nbsp;</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DraftPick;
