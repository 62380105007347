import React, { Component } from "react";
import { isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";

// Components
import DraftBoard from "./draftboard/DraftBoard";
import DraftForm from "./draftboard/DraftForm";
import ChatMessages from "./draftboard/ChatMessages";
import ChatForm from "./draftboard/ChatForm";
import DraftCommishUtils from "./draftboard/DraftCommishUtils";
import DraftShare from "./draftboard/DraftShare";
import DraftEnd from "./draftboard/DraftEnd";

// Material UI
import Button from "@material-ui/core/Button";

// Images
import ListIconOrange from "../img/list-icon-orange.svg";
import SettingsIconOrange from "../img/settings-icon-orange.svg";
import CloseIconWhite from "../img/close-icon-white.svg";

// Actions
import { acceptPreDraftNote } from "../store/actions/draftActions";

// Functions
import { utilFormatPickNumber } from "../util/functions.js";

class DraftActive extends Component {
  state = {
    modalOpen: false,
    activeTab: "board",
    commishToggleOpen: false,
    draftURL: window.location.href
  };

  handleTabChange = e => {
    e.preventDefault();
    this.setState({
      activeTab: e.target.dataset.tab
    });
  };

  handleModalToggle = e => {
    e.preventDefault();
    if (this.state.modalOpen === false) {
      this.setState({
        modalOpen: true
      });
    } else {
      this.setState({
        modalOpen: false
      });
    }
  };

  handleCommishToggle = () => {
    if (this.state.commishToggleOpen === false) {
      this.setState({
        commishToggleOpen: true
      });
    } else {
      this.setState({
        commishToggleOpen: false
      });
    }
  };

  closePreDraftModal = e => {
    e.preventDefault();
    this.props.acceptPreDraftNote({
      draftId: this.props.id,
      userId: this.props.user
    });
  };

  render() {
    const { id, user, profile, draft } = this.props;

    // Get on the clock user
    const getOnTheClock = () => {
      if (draft && draft.status === "Active") {
        var totalUsers = draft.participants;
        if (draft.currentRound % 2 === 0) {
          var snakePick = totalUsers - draft.currentPick;
          return draft.draftOrder[snakePick];
        } else {
          return draft.draftOrder[draft.currentPick - 1];
        }
      }
      return null;
    };

    // Check if user is the commissioner
    const isCommish = () => {
      if (user === draft.createdBy) {
        return true;
      }
      return false;
    };

    // Check if the user is in this draft
    const isDraftUser = () => {
      let allusers = draft.usersArray;
      let thisUser = user;
      if (allusers.includes(thisUser)) {
        return true;
      }
      return false;
    };

    // Check if user has accepted pre-draft note
    const isPreDraftNoteAccepted = () => {
      let acceptedUsers = draft.preDraftNoteAccepted;
      let thisUser = user;
      if (acceptedUsers.includes(thisUser)) {
        return true;
      }
      return false;
    };

    // Return a random headline for the pre-draft note
    const getPreModalHeadline = () => {
      let headlineOptions = [
        "It's on Like Donkey Kong!",
        "It's Draft Time, Baby!",
        "The Game is Afoot!"
      ];
      return headlineOptions[
        Math.floor(Math.random() * headlineOptions.length)
      ];
    };

    // Get class for draftboard scroll wrapper
    const getScrollClass = status => {
      if (status === "Post-Draft") {
        return "fd-draftroom--board--scroll blurred";
      } else if (status === "Complete") {
        return "fd-draftroom--board--scroll complete";
      } else if (status === "Archived") {
        return "fd-draftroom--board--scroll archived";
      } else {
        return "fd-draftroom--board--scroll";
      }
    };

    if (!isLoaded(profile)) {
      return <></>;
    }

    return (
      <div>
        <div
          className={isDraftUser() ? "fd-draftroom" : "fd-draftroom observer"}
        >
          {isDraftUser() && (
            <div className="fd-draftroom--nav">
              <ul>
                <li>
                  <a
                    href="#"
                    data-tab="board"
                    onClick={this.handleTabChange}
                    className={
                      this.state.activeTab === "board" ? "active" : null
                    }
                  >
                    Draft
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    data-tab="chat"
                    onClick={this.handleTabChange}
                    className={
                      this.state.activeTab === "chat" ? "active" : null
                    }
                  >
                    Chat
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div
            className={
              this.state.activeTab === "board"
                ? "fd-draftroom--board active"
                : "fd-draftroom--board"
            }
          >
            <div
              className={
                draft.status === "Post-Draft"
                  ? "fd-draftroom--board--header blurred"
                  : "fd-draftroom--board--header"
              }
            >
              <div
                className={
                  isCommish()
                    ? "fd-draftroom--board--header--title commish"
                    : "fd-draftroom--board--header--title"
                }
              >
                <h1>{draft.title}</h1>
              </div>
              <div className="fd-draftroom--board--header--meta">
                {isCommish() && draft.status === "Active" && (
                  <div
                    className={
                      this.state.commishToggleOpen
                        ? "fd-draftroom--board--header--commish open"
                        : "fd-draftroom--board--header--commish"
                    }
                  >
                    <button onClick={this.handleCommishToggle}>
                      <img src={SettingsIconOrange} alt="Commissioner Tools" />
                      <span>Commish</span>
                    </button>
                  </div>
                )}
                <div className="fd-draftroom--board--header--rules">
                  <button onClick={this.handleModalToggle}>
                    <img src={ListIconOrange} alt="Rules for this Draft" />
                    <span>Rules</span>
                  </button>
                </div>
                {draft.status === "Active" && (
                  <div className="fd-draftroom--board--header--pick">
                    <span className="label">Pick#</span>
                    <span className="number">
                      {utilFormatPickNumber(
                        draft.currentRound,
                        draft.currentPick
                      )}
                    </span>
                  </div>
                )}
              </div>
              {isCommish() && draft.status === "Active" && (
                <div
                  className={
                    this.state.commishToggleOpen
                      ? "fd-draftroom--board--commish open"
                      : "fd-draftroom--board--commish"
                  }
                >
                  <DraftCommishUtils
                    id={id}
                    draft={draft}
                    toggle={this.handleCommishToggle}
                  />
                </div>
              )}
            </div>
            <div className="fd-draftroom--board--board">
              <div className={getScrollClass(draft.status)}>
                {draft.status === "Post-Draft" && (
                  <DraftEnd commish={isCommish()} id={id} draft={draft} />
                )}
                <DraftBoard id={id} user={user} draft={draft} />
              </div>
            </div>
            {isDraftUser() && draft.status === "Active" && (
              <DraftForm
                id={id}
                user={user}
                userName={profile.name}
                draft={draft}
                onTheClock={getOnTheClock()}
              />
            )}
            {draft.status === "Complete" && <DraftShare />}
          </div>
          {isDraftUser() && (
            <div
              className={
                this.state.activeTab === "chat"
                  ? "fd-draftroom--chat active"
                  : "fd-draftroom--chat"
              }
            >
              <div className="fd-draftroom--chat--messages">
                <ChatMessages id={id} tab={this.state.activeTab} />
              </div>
              <div className="fd-draftroom--chat--post">
                <ChatForm id={id} user={user} userName={profile.name} />
              </div>
            </div>
          )}
        </div>
        {!isPreDraftNoteAccepted() && isDraftUser() && (
          <div className="fd-modal open">
            <div className="fd-modal--overlay"></div>
            <div className="fd-modal--content centered pre-draft">
              <h2>{getPreModalHeadline()}</h2>
              <p>A few friendly reminders:</p>
              <ul>
                <li>Draft order has been randomly selected</li>
                <li>
                  Check the "Rules" for any important notes or restrictions from
                  The Commish
                </li>
                <li>
                  Click or tap your avatar to reveal an option to mute sounds
                </li>
                <li>Don't forget to vote for a WINNER!</li>
              </ul>
              <div className="fd-modal--content--button">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={this.closePreDraftModal}
                >
                  Got it. Let's go!
                </Button>
              </div>
            </div>
            <div className="fd-modal--close" onClick={this.closePreDraftModal}>
              <img src={CloseIconWhite} alt="Close Rules" />
            </div>
          </div>
        )}
        <div
          className={
            this.state.modalOpen === true ? "fd-modal open" : "fd-modal"
          }
        >
          <div
            className="fd-modal--overlay"
            onClick={this.handleModalToggle}
          ></div>
          <div className="fd-modal--content">
            {draft.rules && (
              <div>
                <h2>Rules Specific to this Draft:</h2>
                <p>{draft.rules}</p>
              </div>
            )}
            <h2>Basic FunDraft Rules:</h2>
            <ul>
              <li>Draft Order is determined randomly</li>
              <li>No Duplicate Answers</li>
              <li>The Commish can SKIP and UNDO picks</li>
            </ul>
            <div className="fd-modal--content--button">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={this.handleModalToggle}
              >
                Got it. Back to Draft!
              </Button>
            </div>
          </div>
          <div className="fd-modal--close" onClick={this.handleModalToggle}>
            <img src={CloseIconWhite} alt="Close Rules" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    acceptPreDraftNote: data => dispatch(acceptPreDraftNote(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftActive);
