import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from "@firebase/app";
import "@firebase/storage";
import FileUploader from "react-firebase-file-uploader";

// Actions
import { updateAccount } from "../store/actions/accountActions";

// Material UI
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Snackbar from "@material-ui/core/Snackbar";

// Images
import Avatars from "./Avatars";

// Filter for bad words array in username
var filter = require("leo-profanity");
// Remove a few that may prevent valid usernames
filter.remove(["ass", "butt", "tit", "xx"]);
const badWords = filter.list();

class AccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.user,
      name: this.props.profile.name,
      nameHelper: "",
      nameError: false,
      avatar: this.props.profile.avatar,
      alert: "",
      alertOpen: false
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });

    // Validate name
    if (e.target.id === "name") {
      if (e.target.value.length < 2) {
        this.setState({
          nameHelper: "Usernames must be at least 2 characters.",
          nameError: true
        });
      } else if (e.target.value.length > 20) {
        this.setState({
          nameHelper: "Usernames can't be more than 20 characters.",
          nameError: true
        });
      } else {
        this.setState({
          nameHelper: "",
          nameError: false
        });
      }
    }
  };

  validateUsername = name => {
    name = name.toLowerCase();

    // Check if name is clean
    let userIsClean = true;
    badWords.forEach(word => {
      if (name.includes(word)) {
        userIsClean = false;
      }
    });

    if (!userIsClean) {
      this.setState({
        nameHelper: "Usernames must be G-Rated. Please enter another.",
        nameError: true
      });
      return false;
    } else {
      this.setState({
        nameHelper: "",
        nameError: false
      });
      return true;
    }
  };

  handleAvatarClick = avatar => {
    this.setState({
      avatar: avatar
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    let usernameValid = this.validateUsername(this.state.name);

    if (usernameValid) {
      this.props.updateAccount(this.state);
    }
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertOpen: false,
      alert: ""
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.profile.name !== prevProps.profile.name) {
      this.setState({ name: this.props.profile.name });
    }
    if (this.props.profile.avatar !== prevProps.profile.avatar) {
      this.setState({ avatar: this.props.profile.avatar });
    }
  }

  render() {
    const { profile } = this.props;

    const fdAvatars = [
      "robot",
      "bear",
      "hat",
      "bowtie",
      "alien",
      "grandma",
      "hotdog",
      "glasses"
    ];

    return (
      <>
        <form onSubmit={this.handleSubmit} className="account--form">
          <h3>Account Details</h3>
          <div className="field">
            <TextField
              id="name"
              label="Display Name"
              type="text"
              variant="filled"
              value={this.state.name}
              helperText={this.state.nameHelper}
              error={this.state.nameError}
              onChange={this.handleChange}
            />
          </div>
          <div className="field">
            <TextField
              id="handle"
              label="Handle"
              type="text"
              variant="filled"
              value={this.props.profile.handle}
              disabled
            />
          </div>
          <div className="field">
            <TextField
              id="email"
              label="Email"
              type="text"
              variant="filled"
              value={this.props.profile.email}
              disabled
            />
          </div>
          <div className="field fullwidth">
            <div className="account--avatars">
              <h3>Profile Icon</h3>
              <div className="account--avatars--group">
                {fdAvatars.map((avatar, index) => {
                  let buttonClass =
                    avatar == this.state.avatar
                      ? "account--avatars--individual active"
                      : "account--avatars--individual";
                  return (
                    <button
                      type="button"
                      className={buttonClass}
                      id={avatar}
                      key={index}
                      onClick={() => this.handleAvatarClick(avatar)}
                    >
                      <img src={Avatars[avatar]} />
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="field">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </div>
          {this.state.alert !== "" && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={this.state.alertOpen}
              autoHideDuration={3000}
              onClose={this.handleSnackbarClose}
              message={this.state.alert}
              className="success"
            />
          )}
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAccount: data => dispatch(updateAccount(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountForm)
);
