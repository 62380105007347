import React from "react";

// Images
import LoadingSVG from "../img/loading.svg";

const Loading = () => {
  return (
    <div className="loading">
      <img src={LoadingSVG} alt="Loading" />
    </div>
  );
};

export default Loading;
