import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";

// Actions
import { createDraft } from "../store/actions/draftActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

// Components
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";

class NewDraft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      titleHelper: "",
      titleError: false,
      visibility: "Private",
      category: "",
      participants: "",
      rounds: "",
      questionNumber: 1,
      alert: "",
      alertOpen: false,
      roundHours: "0",
      roundMins: "5",
      rules: "",
      draftId: "",
      createdBy: props.auth.uid,
      createdByName: props.profile.name
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.draftId !== prevProps.draftId) {
      this.setState({
        draftId: this.props.draftId
      });
    }
    if (this.props.profile !== prevProps.profile) {
      this.setState({
        createdByName: this.props.profile.name
      });
    }
  }

  componentDidMount() {
    // Set page title
    document.title = "Create A Draft | FunDraft";
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });

    if (e.target.id === "title") {
      if (e.target.value.length > 40) {
        this.setState({
          titleHelper: "Draft titles must be less than 40 characters",
          titleError: true
        });
      } else {
        this.setState({
          titleHelper: "",
          titleError: false
        });
      }
    }
  };

  handleParticipants = num => {
    this.setState({
      participants: num
    });
  };

  handleRounds = num => {
    this.setState({
      rounds: num
    });
  };

  handleVisibility = option => {
    this.setState({
      visibility: option
    });
  };

  handleCategories = cat => {
    this.setState({
      category: cat
    });
  };

  handleNext = e => {
    if (this.state.questionNumber === 1) {
      if (this.state.title === "") {
        this.setState({
          titleHelper: "Please enter something to draft",
          titleError: true
        });
      } else if (this.state.titleError) {
        e.preventDefault();
      } else {
        this.proceedNext();
      }
    }

    if (this.state.questionNumber === 2) {
      if (this.state.category === "") {
        this.setState({ alert: "Please select a category", alertOpen: true });
      } else {
        this.proceedNext();
      }
    }

    if (this.state.questionNumber === 3) {
      if (this.state.participants === "") {
        this.setState({
          alert: "Please select a number of draft participants",
          alertOpen: true
        });
      } else {
        this.proceedNext();
      }
    }

    if (this.state.questionNumber === 4) {
      if (this.state.rounds === "") {
        this.setState({
          alert: "Please select a number of draft rounds",
          alertOpen: true
        });
      } else {
        this.proceedNext();
      }
    }
  };

  proceedNext = () => {
    this.setState({
      questionNumber: this.state.questionNumber + 1,
      alert: ""
    });
  };

  handlePrev = e => {
    this.setState({
      questionNumber: this.state.questionNumber - 1,
      alert: ""
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.category === "") {
      this.setState({ alert: "Please select a category" });
    } else {
      this.props.createDraft(this.state);
    }
  };

  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertOpen: false,
      alert: ""
    });
  };

  render() {
    const { auth, draftId, profile } = this.props;

    // Redirect if not logged in
    if (!auth.uid) {
      return <Redirect to="/login" />;
    }

    // Redirect if logged in but not verified
    if (auth.uid && profile.status !== "active") {
      return <Redirect to="/verify" />;
    }

    if (!isLoaded(profile)) {
      return <Loading />;
    }

    // Redirect to draft URL on successful creation
    if (this.state.draftId !== "") return <Redirect to={`/draft/${draftId}`} />;

    const participantCount = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const roundCount = [2, 4, 6, 8, 10, 12];
    const categoryOptions = [
      { name: "TV & Movies", slug: "tv-movies" },
      { name: "Music", slug: "music" },
      { name: "Sports", slug: "sports" },
      { name: "Food & Drink", slug: "food-drink" },
      { name: "Literature", slug: "literature" },
      { name: "Gaming", slug: "gaming" },
      { name: "Animals", slug: "animals" },
      { name: "People", slug: "people" },
      { name: "Travel", slug: "travel" },
      { name: "Other", slug: "other" }
    ];

    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="twelve columns">
            <h4>Let's Go</h4>
            <h1>Create a Draft</h1>
            {this.state.alert !== "" && (
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={this.state.alertOpen}
                autoHideDuration={3000}
                onClose={this.handleSnackbarClose}
                message={this.state.alert}
                className="error"
              />
            )}
            {this.state.questionNumber === 1 && (
              <div className="field--big">
                <label>What Would You Like to Draft?</label>
                <TextField
                  id="title"
                  value={this.state.title}
                  type="text"
                  variant="filled"
                  className="big"
                  autoComplete="off"
                  helperText={this.state.titleHelper}
                  error={this.state.titleError}
                  onChange={this.handleChange}
                />
              </div>
            )}

            {this.state.questionNumber === 2 && (
              <div className="field--big">
                <label>Pick a Category That Best Matches Your Draft</label>
                <div className="category-button--wrap">
                  {categoryOptions.map((option, i) => {
                    return (
                      <Button
                        key={i}
                        type="button"
                        variant="contained"
                        color="primary"
                        className={
                          this.state.category === option.name
                            ? "category-button active " + option.slug
                            : "category-button " + option.slug
                        }
                        onClick={() => this.handleCategories(option.name)}
                      >
                        {option.name}
                      </Button>
                    );
                  })}
                </div>
              </div>
            )}

            {this.state.questionNumber === 3 && (
              <div className="field--big">
                <label>How Many Participants?</label>
                <div className="number-button--wrap">
                  {participantCount.map((num, i) => {
                    return (
                      <Button
                        key={i}
                        type="button"
                        variant="contained"
                        color="primary"
                        className={
                          this.state.participants === num
                            ? "number-button active"
                            : "number-button"
                        }
                        onClick={() => this.handleParticipants(num)}
                      >
                        {num}
                      </Button>
                    );
                  })}
                </div>
              </div>
            )}

            {this.state.questionNumber === 4 && (
              <div className="field--big">
                <label>How Many Rounds?</label>
                <div className="number-button--wrap">
                  {roundCount.map((num, i) => {
                    return (
                      <Button
                        key={i}
                        type="button"
                        variant="contained"
                        color="primary"
                        className={
                          this.state.rounds === num
                            ? "number-button active"
                            : "number-button"
                        }
                        onClick={() => this.handleRounds(num)}
                      >
                        {num}
                      </Button>
                    );
                  })}
                </div>
              </div>
            )}

            {this.state.questionNumber === 5 && (
              <div>
                <div className="field--big">
                  <label>Any Other Rules People Should Know?</label>
                  <TextField
                    id="rules"
                    value={this.state.rules.replace(/↵/g, "\n")}
                    type="text"
                    variant="filled"
                    autoComplete="off"
                    multiline
                    rows={4}
                    className="multi-line"
                    onChange={this.handleChange}
                    placeholder="Enter Rules (Optional)"
                  />
                </div>
                <div className="field--description">
                  <p>This could include things like:</p>
                  <ul>
                    <li>Specific pick restrictions for this draft topic</li>
                    <li>
                      A time limit to make a pick (you have the power to skip
                      picks)
                    </li>
                    <li>
                      A specific draft start time (once all spots are claimed)
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fd-nav--bottom highlight">
          <div className="prev-next">
            {this.state.questionNumber > 1 && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className="prev"
                onClick={this.handlePrev}
              >
                Back
              </Button>
            )}
            {this.state.questionNumber < 5 && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className="next"
                onClick={this.handleNext}
              >
                Next
              </Button>
            )}
            {this.state.questionNumber > 4 && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className="next"
                onClick={this.handleSubmit}
              >
                Let's Go
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    draftId: state.draft.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createDraft: data => dispatch(createDraft(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDraft);
