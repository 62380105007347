import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { resetPassword } from "../store/actions/authActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Components
import Navbar from "../components/Navbar";

class PasswordReset extends Component {
  state = {
    email: "",
    alert: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.email === "") {
      this.setState({ alert: "Please enter your email address" });
    } else {
      this.props.resetPassword(this.state.email);
    }
  };

  componentDidMount() {
    // Set page title
    document.title = "Reset Password | FunDraft";
  }

  render() {
    const { auth, resetEmailSent } = this.props;

    // Redirect if not logged in and verified
    if (auth.uid && auth.emailVerified) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Navbar />
        <div className="login">
          <div className="container">
            <div className="four columns">
              <h1>Reset Password</h1>
              {this.state.alert !== "" && (
                <div className="error-message">
                  <p>{this.state.alert}</p>
                </div>
              )}
              {resetEmailSent === true ? (
                <div>
                  <p>
                    Password reset email sent.
                    <br />
                    <a href="/login">Back to login</a>
                  </p>
                </div>
              ) : (
                <form onSubmit={this.handleSubmit}>
                  <div className="field">
                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      variant="filled"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field button">
                    <Button type="submit" variant="contained" color="primary">
                      Reset
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetEmailSent: state.auth.resetEmailSent,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: email => dispatch(resetPassword(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
