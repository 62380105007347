import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { removeDraftUser } from "../../store/actions/draftActions";

// Material UI
import Button from "@material-ui/core/Button";

class RemoveUser extends Component {
  state = {
    id: this.props.id,
    user: this.props.user
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.removeDraftUser(this.state);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="user">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="unclaim"
          >
            Unclaim Your Spot
          </Button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeDraftUser: data => dispatch(removeDraftUser(data))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(RemoveUser));
