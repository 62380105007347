import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

// Actions
import { addDraftPick } from "../../store/actions/draftActions";

// Material UI
import Snackbar from "@material-ui/core/Snackbar";

// Images
import SendIcon from "../../img/send-icon.svg";

class DraftForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      user: props.user,
      username: props.userName,
      roundNumber: props.draft.currentRound,
      pickNumber: props.draft.currentPick,
      participants: props.draft.participants,
      rounds: props.draft.rounds,
      pick: "",
      alert: "",
      alertOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    // Check if draft picks have changed
    if (this.props.draft !== prevProps.draft) {
      // Determine who is on the clock
      this.setState({
        roundNumber: this.props.draft.currentRound,
        pickNumber: this.props.draft.currentPick,
        onTheClock: this.props.onTheClock
      });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertOpen: false,
      alert: ""
    });
  };

  checkPreviousPicks = pickMade => {
    let picks = this.props.draftPicks;
    let matchFound = false;
    picks.forEach(pick => {
      if (pickMade.toLowerCase() === pick.pick.toLowerCase()) {
        matchFound = true;
      }
    });
    return matchFound;
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.props.user !== this.props.onTheClock) {
      this.setState({
        alert: "Ah, ah, ah, it's not your turn!",
        alertOpen: true
      });
    } else if (this.state.pick === "") {
      this.setState({
        alert: "Uh oh, your draft pick is empty!",
        alertOpen: true
      });
    } else if (this.state.pick.length > 100) {
      this.setState({
        alert: "Picks cannot be longer than 100 characters",
        alertOpen: true
      });
    } else {
      let pickCheck = this.checkPreviousPicks(this.state.pick);
      if (pickCheck) {
        this.setState({
          alert: "Someone already drafted " + this.state.pick,
          alertOpen: true
        });
      } else {
        this.props.addDraftPick(this.state);
        e.target.reset();
        this.setState({
          pick: ""
        });
      }
    }
  };

  render() {
    const { user, onTheClock } = this.props;

    return (
      <div
        className={
          user === onTheClock
            ? "fd-draftroom--board--post active"
            : "fd-draftroom--board--post"
        }
      >
        <div className="fd-draftroom--board--post--form">
          <form onSubmit={this.handleSubmit}>
            <input
              type="text"
              id="pick"
              placeholder="Your Pick... "
              autoComplete="off"
              onChange={this.handleChange}
            />
            <button type="submit">
              <img src={SendIcon} alt="logo" />
            </button>
          </form>
        </div>
        {this.state.alert !== "" && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={this.state.alertOpen}
            autoHideDuration={3000}
            onClose={this.handleSnackbarClose}
            message={this.state.alert}
            className="error"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const draftPicks = state.firestore.ordered.draftPicks;
  return {
    draftPicks: draftPicks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addDraftPick: data => dispatch(addDraftPick(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    {
      collection: "drafts",
      doc: props.id,
      subcollections: [{ collection: "picks" }],
      orderBy: ["date", "asc"],
      storeAs: "draftPicks"
    }
  ])
)(DraftForm);
