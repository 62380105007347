import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { startDraft } from "../../store/actions/draftActions";

// Material UI
import Button from "@material-ui/core/Button";

class StartDraft extends Component {
  state = {
    id: this.props.id,
    users: this.props.draftUsers
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.startDraft(this.state);
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="user">
          <Button type="submit" variant="contained" color="primary">
            Start Draft
          </Button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startDraft: data => dispatch(startDraft(data))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(StartDraft));
