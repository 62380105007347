import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Components
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import DraftListActive from "../components/DraftListActive";
import DraftListComplete from "../components/DraftListComplete";

class MyDrafts extends Component {
  state = {
    perPageActive: 25,
    perPageComplete: 25
  };

  showMoreActive = current => {
    let more = Number(current) + 25;
    this.setState({
      perPageActive: more
    });
  };

  showMoreComplete = current => {
    let more = Number(current) + 25;
    this.setState({
      perPageComplete: more
    });
  };

  componentDidMount() {
    // Set page title
    document.title = "My Drafts | FunDraft";
  }

  render() {
    const { auth, profile } = this.props;

    // Draft data is loading
    if (!isLoaded(auth, profile)) {
      return <Loading />;
    }

    // Redirect if not logged in or verified
    if (!auth.uid) {
      return <Redirect to="/login" />;
    }

    // Redirect if logged in but not verified
    if (auth.uid && profile.status !== "active") {
      return <Redirect to="/verify" />;
    }

    return (
      <div>
        <Navbar />
        <div className="fd-join">
          <div className="container">
            <div style={{ width: "100%" }}>
              <div className="row">
                <div className="twelve columns">
                  <h1>My Drafts</h1>
                </div>
              </div>
              <div className="row">
                <div className="six columns">
                  <h2>Active Drafts</h2>
                  <DraftListActive
                    perPage={this.state.perPageActive}
                    showMore={this.showMoreActive}
                  />
                </div>
                <div className="six columns">
                  <h2>Completed Drafts</h2>
                  <DraftListComplete
                    perPage={this.state.perPageComplete}
                    showMore={this.showMoreComplete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(MyDrafts);
