import * as firebase from "firebase/app";

// Create the draft record in Firestore
export const updateAccount = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("users")
      .doc(data.id)
      .update({
        name: data.name,
        avatar: data.avatar,
        lastUpdated: new Date()
      })
      .then(() => {
        dispatch({
          type: "UPDATE_USER_ACCOUNT"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_USER_ACCOUNT_ERROR", err });
      });
  };
};

// Set mute setting
export const updateMuteSetting = data => {
  return (dispatch, getState, getFirebase) => {
    const userID = data.userID;
    const muted = data.muted;

    const firestore = getFirebase().firestore();
    firestore
      .collection("users")
      .doc(userID)
      .update({
        muted: muted
      })
      .then(() => {
        dispatch({
          type: "UPDATE_MUTE_SETTING"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_MUTE_SETTING_ERROR", err });
      });
  };
};
