import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Actions
import { skipDraftPick } from "../../store/actions/draftActions";
import { undoDraftPick } from "../../store/actions/draftActions";

// Material UI
import Button from "@material-ui/core/Button";

class DraftCommishUtils extends Component {
  handleSkip = () => {
    let draftData = this.props.draft;
    draftData["draftId"] = this.props.id;
    this.props.skipDraftPick(draftData);
    this.props.toggle();
  };

  handleUndo = () => {
    let orderedPicks = this.props.draftPicks.sort((a, b) => {
      return a.roundNumber - b.roundNumber || a.pickNumber - b.pickNumber;
    });
    let lastPick = orderedPicks[orderedPicks.length - 1];
    lastPick["draftId"] = this.props.id;
    lastPick["status"] = this.props.draft.status;
    this.props.undoDraftPick(lastPick);
    this.props.toggle();
  };

  render() {
    const { draft, draftPicks } = this.props;

    if (!isLoaded(draft) || !isLoaded(draftPicks)) {
      return <></>;
    }

    return (
      <div>
        <h3>Commissioner Tools</h3>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={draft.status === "Post-Draft" ? true : false}
          onClick={() => this.handleSkip()}
        >
          Skip This Pick
        </Button>
        {draftPicks && draftPicks.length > 0 && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => this.handleUndo()}
          >
            Undo Last Pick
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const draftPicks = state.firestore.ordered.draftPicks;
  return {
    draftPicks: draftPicks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    skipDraftPick: data => dispatch(skipDraftPick(data)),
    undoDraftPick: data => dispatch(undoDraftPick(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    {
      collection: "drafts",
      doc: props.id,
      subcollections: [{ collection: "picks" }],
      orderBy: ["date", "asc"],
      storeAs: "draftPicks"
    }
  ])
)(DraftCommishUtils);
