import React, { Component } from "react";

// Components
import DraftBoard from "./draftboard/DraftBoard";
import DraftShare from "./draftboard/DraftShare";

// Images
import ListIconOrange from "../img/list-icon-orange.svg";
import CloseIconWhite from "../img/close-icon-white.svg";

class DraftComplete extends Component {
  state = {
    modalOpen: false
  };

  handleModalToggle = e => {
    e.preventDefault();
    if (this.state.modalOpen === false) {
      this.setState({
        modalOpen: true
      });
    } else {
      this.setState({
        modalOpen: false
      });
    }
  };

  render() {
    const { id, user, draft } = this.props;

    // Get class for draftboard scroll wrapper
    const getScrollClass = status => {
      if (status === "Post-Draft") {
        return "fd-draftroom--board--scroll blurred";
      } else if (status === "Complete") {
        return "fd-draftroom--board--scroll complete";
      } else if (status === "Archived") {
        return "fd-draftroom--board--scroll archived";
      } else {
        return "fd-draftroom--board--scroll";
      }
    };

    return (
      <div>
        <div className="fd-draftroom observer">
          <div className="fd-draftroom--board active">
            <div className="fd-draftroom--board--header">
              <div className="fd-draftroom--board--header--title">
                <h1>{draft.title}</h1>
              </div>
              <div className="fd-draftroom--board--header--meta">
                <div className="fd-draftroom--board--header--rules">
                  <button onClick={this.handleModalToggle}>
                    <img src={ListIconOrange} alt="Rules for this Draft" />
                    <span>Rules</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="fd-draftroom--board--board">
              <div className={getScrollClass(draft.status)}>
                <DraftBoard id={id} user={user} draft={draft} />
              </div>
            </div>
            <DraftShare />
          </div>
        </div>

        <div
          className={
            this.state.modalOpen === true ? "fd-modal open" : "fd-modal"
          }
        >
          <div
            className="fd-modal--overlay"
            onClick={this.handleModalToggle}
          ></div>
          <div className="fd-modal--content">
            {draft.rules && (
              <div>
                <h3>Rules Specific to this Draft:</h3>
                <p>{draft.rules}</p>
              </div>
            )}
            <h3>Basic FunDraft Rules:</h3>
            <ul>
              <li>Draft Order is determined randomly.</li>
              <li>No Duplicate Answers.</li>
              <li>All picks are final and the draft cannot be paused.</li>
            </ul>
          </div>
          <div className="fd-modal--close" onClick={this.handleModalToggle}>
            <img src={CloseIconWhite} alt="Close Rules" />
          </div>
        </div>
      </div>
    );
  }
}

export default DraftComplete;
