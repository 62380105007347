import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";

// Functions
import { utilFormatPickNumber } from "../../util/functions.js";

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

class ChatMessages extends Component {
  scrollToMsgBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.scrollToMsgBottom();
  }

  componentDidUpdate(prevProps) {
    if (this.props.draftChat !== prevProps.draftChat) {
      this.scrollToMsgBottom();
    }
    if (this.props.tab !== prevProps.tab) {
      this.scrollToMsgBottom();
    }
  }

  render() {
    const { draftChat } = this.props;

    return (
      <div>
        {draftChat &&
          draftChat.map((msg, index) => (
            <div key={index} className="fd-draftroom--chat--messages--msg">
              <div className={msg.type}>
                {msg.type === "pick" && (
                  <div className="pick-note">
                    <span>
                      Pick#{" "}
                      {utilFormatPickNumber(msg.roundNumber, msg.pickNumber)}
                    </span>
                  </div>
                )}
                {msg.type === "admin" && msg.systemHeader && (
                  <div className="pick-note">
                    <span>{msg.systemHeader}</span>
                  </div>
                )}
                <div className="meta">
                  <span className="user">{msg.name}</span>
                  <span className="date">
                    {timeAgo.format(new Date(msg.date.seconds * 1000))}
                  </span>
                </div>
                {(msg.type === "text" ||
                  msg.type === "pick" ||
                  msg.type === "admin") && (
                  <span className="msg">{msg.message}</span>
                )}
                {msg.type === "gif" && (
                  <span className="msg">
                    <img
                      src={msg.message}
                      onLoad={() => this.scrollToMsgBottom()}
                      alt="GIF Message"
                    />
                  </span>
                )}
              </div>
            </div>
          ))}
        <div
          className="messages-end"
          ref={el => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const draftChat = state.firestore.ordered.draftChat;
  return {
    draftChat: draftChat
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: "drafts",
      doc: props.id,
      subcollections: [{ collection: "chat" }],
      orderBy: ["date", "asc"],
      storeAs: "draftChat"
    }
  ])
)(ChatMessages);
