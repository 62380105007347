import React, { Component } from "react";

class DraftUserAvatar extends Component {
  constructor() {
    super();
    this.state = { loaded: false };
  }

  render() {
    const { avatar, name, customAvatar } = this.props;

    return (
      <div className="fd-draftroom--board--label--avatar">
        {customAvatar ? (
          <div className="avatar with-background">
            <img
              src={customAvatar}
              alt={name + " avatar"}
              style={this.state.loaded ? {} : { display: "none" }}
              onLoad={() => this.setState({ loaded: true })}
            />
          </div>
        ) : (
          <div className="avatar with-background">
            <img
              src={avatar}
              alt={name + " avatar"}
              style={this.state.loaded ? {} : { display: "none" }}
              onLoad={() => this.setState({ loaded: true })}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DraftUserAvatar;
