import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Material UI
import DraftUserAvatar from "./DraftUserAvatar";

// Images
import Avatars from "../Avatars";

class DraftUser extends Component {
  render() {
    const { id, isWinner, hasVoted, onTheClock, isCommish, user } = this.props;

    // TODO: Better loading styling?
    if (!isLoaded(user) || !user) {
      return <div></div>;
    }

    const getLabelClass = () => {
      let labelClass = "fd-draftroom--board--label";
      if (id === onTheClock) {
        labelClass += " active";
      }
      if (isCommish) {
        labelClass += " commish";
      }
      return labelClass;
    };

    return (
      <div
        className={
          hasVoted && isWinner ? "vote-wrapper winner" : "vote-wrapper"
        }
      >
        <div className={getLabelClass()}>
          <div className="fd-draftroom--board--label--avatar">
            <div className="avatar with-background">
              <img src={Avatars[user.avatar]} alt={user.name + " avatar"} />
            </div>
          </div>
          <DraftUserAvatar
            avatar={user.avatar}
            customAvatar={user.customAvatar}
            name={user.name}
          />
          <div className="fd-draftroom--board--label--player">
            <span className="name">{user.name}</span>
            <span className="handle">@{user.handle}</span>
          </div>
          {isCommish && <span className="commish-label">Commish</span>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const users = state.firestore.data.users;
  const user = users ? users[ownProps.id] : null;
  return {
    user: user
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: "users",
      doc: props.id
    }
  ])
)(DraftUser);
