export default {
  default: require("../img/avatars/fd-avatar-robot.png"),
  robot: require("../img/avatars/fd-avatar-robot.png"),
  bear: require("../img/avatars/fd-avatar-bear.png"),
  hat: require("../img/avatars/fd-avatar-hat.png"),
  bowtie: require("../img/avatars/fd-avatar-bowtie.png"),
  alien: require("../img/avatars/fd-avatar-alien.png"),
  grandma: require("../img/avatars/fd-avatar-grandma.png"),
  hotdog: require("../img/avatars/fd-avatar-hotdog.png"),
  glasses: require("../img/avatars/fd-avatar-glasses.png")
};
