import React from "react";

// Components
import User from "./User";

const UserList = ({ users, participants, createdBy }) => {
  var usersSigned = users.length;
  var usersLeft = participants - usersSigned;

  return (
    <ul className="fd-waitroom--players">
      {usersSigned > 0 &&
        users.map(user => (
          <User id={user.id} createdBy={createdBy} key={user.id} />
        ))}
      {[...Array(usersLeft)].map((value: undefined, index: number) => (
        <li className="empty" key={index}>
          Spot Available to Claim
        </li>
      ))}
    </ul>
  );
};

export default UserList;
