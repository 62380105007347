import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

// Images
import Avatars from "../Avatars";

class User extends Component {
  render() {
    const { id, createdBy, user } = this.props;

    // TODO: Better loading styling?
    if (!isLoaded(user) || !user) {
      return <div></div>;
    }

    return (
      <li className="claimed">
        <div className="fd-waitroom--players--avatar">
          <div className="fd-waitroom--players--avatar">
            {user.customAvatar ? (
              <img src={user.customAvatar} alt={user.name + " avatar"} />
            ) : (
              <img src={Avatars[user.avatar]} alt={user.name + " avatar"} />
            )}
          </div>
        </div>
        <div className="fd-waitroom--players--name">
          <div className="fd-waitroom--players--name--username">
            {user.name}
          </div>
          <div className="fd-waitroom--players--name--handle">
            @{user.handle}
          </div>
        </div>
        {id === createdBy && <span>Commish</span>}
      </li>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const users = state.firestore.data.users;
  const user = users ? users[ownProps.id] : null;
  return {
    user: user
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: "users",
      doc: props.id
    }
  ])
)(User);
