const initState = {};

const accountReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_USER_ACCOUNT":
      return {
        ...state
      };
    case "UPDATE_USER_ACCOUNT_ERROR":
      return {
        ...state,
        error: "Error Updating User Account"
      };
    case "UPDATE_MUTE_SETTING":
      return {
        ...state
      };
    case "UPDATE_MUTE_SETTING_ERROR":
      return {
        ...state,
        error: "Error Updating Mute Setting"
      };
    default:
      return state;
  }
};

export default accountReducer;
