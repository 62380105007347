import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";

// Actions
import { verifyUser } from "../store/actions/authActions";

// Material UI
import Snackbar from "@material-ui/core/Snackbar";

// Components
import Loading from "../components/Loading";

class VerifyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      alertOpen: false,
      alertClass: "success"
    };
  }

  componentDidMount() {
    // Verify
    if (this.props.match.params.userId) {
      this.props.verifyUser({ id: this.props.match.params.userId });
    }
  }

  render() {
    const { auth, match, profile } = this.props;

    // Redirect if not logged in
    if (!auth.uid) {
      return <Redirect to="/verify" />;
    }

    // Redirect if not logged in
    if (!match.params.userId) {
      return <Redirect to="/verify" />;
    }

    // Redirect if logged in and verified
    if (isLoaded(profile) && profile.status === "active") {
      return <Redirect to="/verify" />;
    }

    return <Loading />;
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyUser: data => dispatch(verifyUser(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);
