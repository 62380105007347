export const utilFormatTimestamp = inputDate => {
  let date = new Date(inputDate * 1000);
  let year = date.getFullYear();
  let month = date.toLocaleString("default", { month: "short" });
  let day = date.getDate();
  let hours = date.getHours();
  let ampm = hours >= 12 ? "pm" : "am";
  if (hours > 12) {
    hours = hours - 12;
  }
  let minutes = "0" + date.getMinutes();
  let formattedDate =
    month +
    " " +
    day +
    ", " +
    year +
    " at " +
    hours +
    ":" +
    minutes.substr(-2) +
    ampm;
  return formattedDate;
};

export const utilFormatDate = inputDate => {
  let date = new Date(inputDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let formattedDate = month + "/" + day + "/" + year;
  return formattedDate;
};

export const utilFormatPickNumber = (round, pick) => {
  var pickNumber = pick;
  if (pickNumber.toString().length === 1) {
    pickNumber = "0" + pickNumber;
  }
  return round + "." + pickNumber;
};
