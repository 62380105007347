import React, { useEffect } from "react";

// Components
import Navbar from "../components/Navbar";

// Images
import SnakeDraftExample from "../img/snake-draft-example.png";

const WhatIsFundraft = () => {
  // Set page title
  useEffect(() => {
    document.title = "What is FunDraft? | FunDraft";
  });

  return (
    <div>
      <Navbar />
      <div className="fd-join">
        <div className="container">
          <div className="column-content">
            <h1>What is FunDraft?</h1>
            <div className="text-content">
              <p>
                FunDraft is a social gaming web app where players take turns
                picking favorites of any topic! What are your favorite TV shows?
                How about favorite fast food restaurants? Finally NON-sports
                fans can feel the excitement and social interaction of a fantasy
                draft! It's a unique way to connect and interact with friends,
                family, and social media followers. Play in a web browser on any
                device!
              </p>
              <h2>What's the End Game?</h2>
              <p>
                Besides the fun of making your picks and sending GIFs with the
                built-in chat ... Become the CHAMP of your draft! Once the draft
                is over, anyone with the link to the draft and a FunDraft
                account can vote for a winner! Voting is anonymous and vote
                counts are hidden until you have voted.
              </p>
              <p>
                Need to settle a score? Share your draft on social media and see
                what other people have to say about your picks.
              </p>
              <h2>How does a FunDraft get started?</h2>

              <ul>
                <li>Create a free FunDraft account if you haven't yet.</li>
                <li>
                  From the main menu, someone selects Create a Draft, and
                  therefore becomes the Commissioner (Commish) of the draft.
                </li>
                <li>
                  The Commish chooses the settings for the FunDraft, including:
                  draft topic, number of players, and number of rounds.
                </li>
                <li>The Commish invites others to join.</li>
                <li>
                  The Commish can start the draft when all spots are claimed!
                </li>
              </ul>
              <h2>How to Play / Basic FunDraft Rules</h2>
              <ul>
                <li>
                  Once the FunDraft begins, players take turns making picks
                  based on the draft topic. Quite simply, when it’s your turn,
                  you enter your pick! No duplicate picks allowed.
                  <ul>
                    <li>
                      <em>
                        <strong>Example:</strong>
                      </em>{" "}
                      The draft topic is Best Foods, and the first two picks
                      made were Pizza and Burgers. Now it’s your turn and you
                      wouldn’t want to type in “Cheeseburgers” since Burgers has
                      already been picked.
                    </li>
                  </ul>
                </li>
                <li>
                  There is no official Pick Timer, but The Commish can choose to
                  SKIP anyone who is taking too long!
                </li>
                <li>
                  Click the "Rules" link to see any notes or restrictions The
                  Commish has entered for that specific FunDraft. The Commish is
                  able to undo picks as needed.
                </li>
                <li>
                  Draft Order is always determined randomly. You find out your
                  draft spot the moment the draft has started.
                </li>
                <li>
                  Every FunDraft is a “snake draft” format.{" "}
                  <em>
                    <strong>
                      This means that the draft order reverses every round.
                    </strong>
                  </em>{" "}
                  While having the FIRST overall pick in the draft is great,
                  that means you have the LAST pick in the next round.
                </li>
              </ul>
              <h2>Snake Draft Example</h2>
              <p>
                <img src={SnakeDraftExample} alt="Snake Draft Example" />
              </p>
              <p>
                Once a FunDraft begins, anyone with the link can View the draft
                board. However, the Chat section is for those participating in
                the draft only, and will be hidden from spectators. Once a
                FunDraft is over, anyone with the link can vote for the winner!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsFundraft;
