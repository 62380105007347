import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyAQqft-i1pUM6BKRsrJ4RIw6H5prqp1Gy0",
  authDomain: "fundraft-5d48c.firebaseapp.com",
  databaseURL: "https://fundraft-5d48c.firebaseio.com",
  projectId: "fundraft-5d48c",
  storageBucket: "fundraft-5d48c.appspot.com",
  messagingSenderId: "232946772927",
  appId: "1:232946772927:web:028a4a4d161a8480fa0f0a",
  measurementId: "G-B9Q04SE04H"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();

let firebaseAnalytics: firebase.analytics.Analytics;

export async function initFirebaseAnalytics(): Promise<firebase.analytics.Analytics> {
  if (!firebaseAnalytics) {
    await require("firebase/analytics");
    firebaseAnalytics = await firebase.analytics();
  }
  return firebaseAnalytics;
}

export default firebase;
