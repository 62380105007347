import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";

// Actions
import { signIn } from "../store/actions/authActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Components
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";

class Login extends Component {
  state = {
    email: "",
    emailHelper: "",
    emailError: false,
    password: "",
    passwordHelper: "",
    passwordError: false,
    loginError: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    var fields = ["email", "password"];
    // Return helper text if field is empty
    fields.forEach(field => {
      if (this.state[field] === "") {
        this.setState({
          [field + "Helper"]: "Please fill out this field.",
          [field + "Error"]: true
        });
        return;
      }
    });
    // Sign in
    this.props.signIn(this.state);
  };

  // Handle auth errors on prop change
  componentDidUpdate(prevProps) {
    if (this.props.authError !== prevProps.authError) {
      if (
        this.props.authError &&
        this.props.authError.code &&
        this.props.authError.code === "auth/user-not-found"
      ) {
        this.setState({
          emailHelper: "Email address not found. Please check spelling.",
          emailError: true
        });
      }
      if (
        this.props.authError &&
        this.props.authError.code &&
        this.props.authError.code === "auth/wrong-password"
      ) {
        this.setState({
          passwordHelper: "Wrong password. Try again or reset it below.",
          passwordError: true
        });
      }
      if (
        this.props.authError &&
        this.props.authError.code &&
        this.props.authError.code === "auth/too-many-requests"
      ) {
        this.setState({
          loginError: this.props.authError.message
        });
      }
    }
  }

  componentDidMount() {
    // Set page title
    document.title = "FunDraft | Draft Anything";
  }

  render() {
    const { auth, profile } = this.props;

    // Auth data is loading
    if (!isLoaded(auth, profile)) {
      return <Loading />;
    }

    // Redirect if logged in with active account
    if (auth.uid && profile.status === "active") return <Redirect to="/" />;

    // Redirect if logged in with pending account
    if (auth.uid && profile.status === "pending")
      return <Redirect to="/verify" />;

    return (
      <div>
        <Navbar />
        <div className="login">
          <div className="container centered">
            <div className="column-content short">
              <h1>Sign in</h1>
              {this.state.loginError !== "" && (
                <div className="error-message">
                  <p>{this.state.loginError}</p>
                </div>
              )}
              <p>
                <strong>
                  <NavLink to="/what-is-fundraft">What is FunDraft?</NavLink>
                </strong>
              </p>
              <form onSubmit={this.handleSubmit}>
                <div className="field">
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    variant="filled"
                    helperText={this.state.emailHelper}
                    error={this.state.emailError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="field">
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="filled"
                    helperText={this.state.passwordHelper}
                    error={this.state.passwordError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="field button">
                  <Button type="submit" variant="contained" color="primary">
                    Sign in
                  </Button>
                </div>
              </form>
              <NavLink to="/signup" className="button-wrap">
                <Button variant="contained" color="secondary">
                  Sign up
                </Button>
              </NavLink>
              <p>
                Can't remember your password? <br />
                <NavLink to="/reset">Reset it here</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => dispatch(signIn(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
