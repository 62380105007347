import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGiphySearchbox from "react-giphy-searchbox";

// Actions
import { addDraftMsg } from "../../store/actions/draftActions";

// Images
import SendIcon from "../../img/send-icon.svg";
import CloseIconWhite from "../../img/close-icon-white.svg";

class ChatForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      user: props.user,
      username: props.userName,
      message: "",
      type: "text",
      gifPreview: "",
      sendActive: false,
      giphyActive: false
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    if (e.target.value !== "") {
      this.setState({
        sendActive: true
      });
    } else {
      this.setState({
        sendActive: false
      });
    }
  };

  handleGifSelect = gif => {
    var gifURL = gif.images.downsized_medium.url;
    this.setState({
      message: gifURL,
      gifPreview: gifURL,
      type: "gif",
      giphyActive: false,
      sendActive: true
    });
    this.sendButton.focus();
  };

  handleGifRemove = gif => {
    this.setState({
      message: "",
      gifPreview: "",
      type: "text"
    });
  };

  handleGiphyToggle = e => {
    if (this.state.giphyActive === true) {
      this.setState({
        giphyActive: false
      });
    } else {
      this.setState({
        giphyActive: true
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.message !== "") {
      this.props.addDraftMsg(this.state);
    }
    e.target.reset();
    this.setState({
      message: "",
      type: "text",
      gifPreview: "",
      sendActive: false,
      giphyActive: false
    });
  };

  render() {
    let windowWidth = window.innerWidth;
    let giphyWidth = windowWidth - 40;
    let giphyColumns = 2;
    if (windowWidth > 480) {
      giphyColumns = 3;
    }
    if (windowWidth > 600) {
      giphyColumns = 4;
    }
    if (windowWidth > 768) {
      giphyColumns = 2;
      giphyWidth = 300;
    }

    let giphyColumnWidth =
      (giphyWidth - (giphyColumns - 1) * 10) / giphyColumns;

    return (
      <div>
        <div className="fd-draftroom--chat--post--form">
          <form onSubmit={this.handleSubmit}>
            <div className="inputs">
              {this.state.gifPreview !== "" ? (
                <div className="gif-preview">
                  <img src={this.state.gifPreview} alt="Giphy Preview" />
                  <span className="gif-remove" onClick={this.handleGifRemove}>
                    <img src={CloseIconWhite} alt="Remove Selected Gif" />
                  </span>
                </div>
              ) : (
                <textarea
                  id="message"
                  placeholder="Message... "
                  autoComplete="off"
                  rows="1"
                  onChange={this.handleChange}
                />
              )}
            </div>
            <div className="buttons">
              <button
                type="button"
                className={
                  this.state.giphyActive
                    ? "giphy-button"
                    : "giphy-button disabled"
                }
                onClick={this.handleGiphyToggle}
              >
                GIF
              </button>
              <button
                type="submit"
                className={this.state.sendActive ? "" : "disabled"}
                ref={el => {
                  this.sendButton = el;
                }}
              >
                <img src={SendIcon} alt="Send Message" />
              </button>
            </div>
          </form>
        </div>
        <div
          className={
            this.state.giphyActive
              ? "fd-draftroom--chat--post--giphy open"
              : "fd-draftroom--chat--post--giphy"
          }
        >
          <ReactGiphySearchbox
            apiKey="lm5vCIg0ufz4XlkvsWv26knS0M3Ki2YL"
            onSelect={item => this.handleGifSelect(item)}
            masonryConfig={[
              {
                columns: giphyColumns,
                imageWidth: giphyColumnWidth,
                gutter: 10
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addDraftMsg: data => dispatch(addDraftMsg(data))
  };
};

export default connect(null, mapDispatchToProps)(ChatForm);
