import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Actions
import { createUser } from "../store/actions/authActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

// Components
import Navbar from "../components/Navbar";

// Filter for bad words array in username
var filter = require("leo-profanity");
// Remove a few that may prevent valid usernames
filter.remove(["ass", "butt", "tit", "xx"]);
const badWords = filter.list();

class SignUp extends Component {
  state = {
    name: "",
    nameHelper: "",
    nameError: false,
    email: "",
    emailHelper: "",
    emailError: false,
    password: "",
    passwordHelper: "",
    passwordError: false,
    passwordMatch: "",
    passwordMatchHelper: "",
    passwordMatchError: false,
    alert: "",
    alertOpen: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.authError !== prevProps.authError) {
      if (this.props.authError.code === "auth/username-taken") {
        this.setState({
          nameHelper: "This username is already taken. Please choose another.",
          nameError: true
        });
      }
      if (this.props.authError.code === "auth/email-already-in-use") {
        this.setState({
          emailHelper:
            "This email address is already in use by another account.",
          emailError: true
        });
      }
    }
  }

  componentDidMount() {
    // Set page title
    document.title = "Sign Up | FunDraft";
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
      [e.target.id + "Helper"]: "",
      [e.target.id + "Error"]: false
    });

    // Validate name
    if (e.target.id === "name") {
      if (e.target.value.length < 4) {
        this.setState({
          nameHelper: "Usernames must be at least 4 characters.",
          nameError: true
        });
      } else if (e.target.value.length > 20) {
        this.setState({
          nameHelper: "Usernames can't be more than 20 characters.",
          nameError: true
        });
      } else {
        this.setState({
          nameHelper: "",
          nameError: false
        });
      }
    }

    // Validate password
    if (e.target.id === "password") {
      if (e.target.value.length < 6) {
        this.setState({
          passwordHelper: "Passwords must be at least 6 characters.",
          passwordError: true
        });
      } else {
        this.setState({
          passwordHelper: "",
          passwordError: false
        });
      }
    }

    // Validate password match
    if (e.target.id === "passwordMatch") {
      if (e.target.value !== this.state.password) {
        this.setState({
          passwordMatchHelper: "Password does not match.",
          passwordMatchError: true
        });
      } else {
        this.setState({
          passwordMatchHelper: "",
          passwordMatchError: false
        });
      }
    }
  };

  validateUsername = name => {
    // Check if name contains spaces
    let userisAlphaNumberic = false;
    let letterNumber = /^[0-9a-zA-Z]+$/;
    if (name.match(letterNumber)) {
      userisAlphaNumberic = true;
    }

    // Check if name is clean
    let userIsClean = true;
    badWords.forEach(word => {
      if (name.includes(word)) {
        userIsClean = false;
      }
    });

    if (!userisAlphaNumberic) {
      this.setState({
        nameHelper: "Usernames must be alphanumeric (A-Z and 0-9)",
        nameError: true
      });
      return false;
    } else if (!userIsClean) {
      this.setState({
        nameHelper: "Usernames must be G-Rated. Please enter another.",
        nameError: true
      });
      return false;
    } else {
      this.setState({
        nameHelper: "",
        nameError: false
      });
      return true;
    }
  };

  validateFields = () => {
    let allFieldsValid = true;
    const fields = ["name", "email", "password", "passwordMatch"];
    // Return helper text if field is empty
    fields.forEach(field => {
      if (this.state[field] === "") {
        this.setState({
          [field + "Helper"]: "Please fill out this field.",
          [field + "Error"]: true
        });
        allFieldsValid = false;
        return false;
      }
      if (this.state[field + "Error"]) {
        allFieldsValid = false;
        return false;
      }
    });
    return allFieldsValid;
  };

  handleSubmit = e => {
    e.preventDefault();

    let usernameValid = this.validateUsername(this.state.name);
    let fieldsValid = this.validateFields();

    if (usernameValid && fieldsValid) {
      // Sign up user
      this.props.createUser(this.state);
    }
  };
  render() {
    const { auth } = this.props;

    // Redirect if logged in and verified
    if (auth.uid && auth.emailVerified) return <Redirect to="/" />;

    // Redirect if logged in and not verified
    if (auth.uid && !auth.emailVerified) return <Redirect to="/verify" />;

    return (
      <div>
        <Navbar />
        <div className="login">
          <div className="container">
            <div className="column-content short">
              <h1>Join FunDraft</h1>
              <form onSubmit={this.handleSubmit}>
                <div className="field">
                  <TextField
                    id="name"
                    label="Username"
                    type="text"
                    variant="filled"
                    autoComplete="off"
                    helperText={this.state.nameHelper}
                    error={this.state.nameError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="field half">
                  <TextField
                    id="email"
                    label="Email Address"
                    type="email"
                    variant="filled"
                    autoComplete="off"
                    helperText={this.state.emailHelper}
                    error={this.state.emailError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="field half">
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="filled"
                    helperText={this.state.passwordHelper}
                    error={this.state.passwordError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="field half">
                  <TextField
                    id="passwordMatch"
                    label="Re-Enter Password"
                    type="password"
                    variant="filled"
                    helperText={this.state.passwordMatchHelper}
                    error={this.state.passwordMatchError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="field">
                  <div className="signup-terms">
                    <p>
                      <strong>
                        Note: Username and email address cannot be changed after
                        signup
                      </strong>
                    </p>
                    <p>
                      By clicking Sign Up, you are indicating that you have read
                      and acknowledge the FunDraft{" "}
                      <a
                        href="https://www.termsfeed.com/live/72f3547a-348a-4971-8acd-2972249703b0"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.termsfeed.com/live/f5203abb-8163-4abc-8c3d-27c44bf246f7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="field">
                  <Button type="submit" variant="contained" color="primary">
                    Sign Up
                  </Button>
                </div>
              </form>
              {this.state.alert !== "" && (
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  open={this.state.alertOpen}
                  autoHideDuration={4000}
                  onClose={this.handleSnackbarClose}
                  message={this.state.alert}
                  className="error"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUser: user => dispatch(createUser(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
