import React from "react";

// Components
import Navbar from "../components/Navbar";

// Images
import NotFound from "../img/404-error.png";

const DraftNotFound = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="not-found-error">
          <img src={NotFound} alt="404 Page Not Found" />
          <h1>Draft Not Found</h1>
          <p>
            Sorry dude, this draft doesn't exist. Get started back on the right
            path in the menu above.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DraftNotFound;
