import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./css/app.css";

// Components
import ScrollToTop from "./ScrollToTop";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Verify from "./pages/Verify";
import VerifyUser from "./pages/VerifyUser";
import NewDraft from "./pages/NewDraft";
import SingleDraft from "./pages/SingleDraft";
import MyDrafts from "./pages/MyDrafts";
import WhatIsFundraft from "./pages/WhatIsFundraft";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Account from "./pages/Account";
import PasswordReset from "./pages/PasswordReset";
import Disclosures from "./pages/Disclosures";

class App extends Component {
  render() {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    return (
      <div className="App">
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/verify" component={Verify} />
              <Route exact path="/verify/:userId" component={VerifyUser} />
              <Route exact path="/new" component={NewDraft} />
              <Route exact path="/draft/:id" component={SingleDraft} />
              <Route exact path="/my-drafts" component={MyDrafts} />
              <Route
                exact
                path="/what-is-fundraft"
                component={WhatIsFundraft}
              />
              <Route exact path="/about-us" component={About} />
              <Route exact path="/contact-us" component={Contact} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/reset" component={PasswordReset} />
              <Route exact path="/disclosures" component={Disclosures} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}

export default App;
