import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";

// Components
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import AccountForm from "../components/AccountForm";

const Account = ({ auth, profile }) => {
  // Set page title
  useEffect(() => {
    document.title = "My Account | FunDraft";
  });

  // Redirect if not logged in or verified
  if (!auth.uid) {
    return <Redirect to="/login" />;
  }

  // Redirect if logged in but not verified
  if (auth.uid && profile.status !== "active") {
    return <Redirect to="/verify" />;
  }

  // Draft data is loading
  if (!isLoaded(profile)) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="twelve columns">
          <div className="account">
            <h4>Your Account</h4>
            <h1 className="name">{profile.name}</h1>
            <AccountForm profile={profile} user={auth.uid} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Account);
