const initState = {};

const draftReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_DRAFT":
      return {
        ...state,
        id: action.draftID
      };
    case "CREATE_DRAFT_ERROR":
      return {
        ...state,
        error: "Error Creating Draft"
      };
    case "ADD_DRAFT_USER":
      return {
        ...state
      };
    case "ADD_DRAFT_USER_ERROR":
      return {
        ...state,
        error: "Error Claiming Draft Spot"
      };
    case "REMOVE_DRAFT_USER":
      return {
        ...state
      };
    case "REMOVE_DRAFT_USER_ERROR":
      return {
        ...state,
        error: "Error Removing Users from Draft"
      };
    case "START_DRAFT":
      return {
        ...state
      };
    case "START_DRAFT_ERROR":
      return {
        ...state,
        error: "Error Starting Draft"
      };
    case "ADD_DRAFT_PICK":
      return {
        ...state
      };
    case "ADD_DRAFT_PICK_ERROR":
      return {
        ...state,
        error: "Error Adding Draft Pick"
      };
    case "UNDO_DRAFT_PICK":
      return {
        ...state
      };
    case "UNDO_DRAFT_PICK_ERROR":
      return {
        ...state,
        error: "Error Adding Draft Pick"
      };
    case "SKIP_DRAFT_PICK":
      return {
        ...state
      };
    case "SKIP_DRAFT_PICK_ERROR":
      return {
        ...state,
        error: "Error Skipping Draft Pick"
      };
    case "ADD_DRAFT_MSG":
      return {
        ...state
      };
    case "ADD_DRAFT_MSG_ERROR":
      return {
        ...state,
        error: "Error Adding Chat Message"
      };
    default:
      return state;
  }
};

export default draftReducer;
