import React from "react";

// Images
import LoadingSVG from "../img/loading.svg";

const LoadingWidget = () => {
  return (
    <div className="loading widget">
      <img src={LoadingSVG} alt="Loading" />
    </div>
  );
};

export default LoadingWidget;
